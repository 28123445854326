import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _ from 'lodash';
export var removeNavLinkIcon = function removeNavLinkIcon(_ref) {
  var navLink = _ref.navLink,
    rest = _objectWithoutProperties(_ref, ["navLink"]);
  if (!navLink) return rest;
  return _objectSpread({}, rest, {
    navLink: _.omit(navLink, 'icon')
  });
};
export var shouldShowIcons = function shouldShowIcons(routes) {
  var showOnNavBarRoutes = _filterInstanceProperty(routes).call(routes, function (route) {
    return _.get(route, 'showOnNavBar', true);
  });
  var hasNavLinkRoutes = _everyInstanceProperty(showOnNavBarRoutes).call(showOnNavBarRoutes, function (_ref2) {
    var navLink = _ref2.navLink;
    return navLink;
  });
  return hasNavLinkRoutes && _everyInstanceProperty(showOnNavBarRoutes).call(showOnNavBarRoutes, function (_ref3) {
    var icon = _ref3.navLink.icon;
    return icon;
  });
};