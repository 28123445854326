import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { Loader } from '../loading/loader/Loader';
import styles from "./Image.module.css";
var imagePropTypes = {
  src: PropTypes.string.isRequired,
  alt: intlMessageShape,
  className: PropTypes.string,
  figcaption: intlMessageShape,
  width: PropTypes.number,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  showLoader: PropTypes.bool
};
export var Image = function Image(_ref) {
  var src = _ref.src,
    alt = _ref.alt,
    className = _ref.className,
    figcaption = _ref.figcaption,
    onError = _ref.onError,
    onLoad = _ref.onLoad,
    showLoader = _ref.showLoader,
    dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
    rest = _objectWithoutProperties(_ref, ["src", "alt", "className", "figcaption", "onError", "onLoad", "showLoader", "dangerouslySetInnerHTML"]);
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    imageLoading = _useState2[0],
    setImageLoading = _useState2[1];
  useEffect(function () {
    setImageLoading(true);
  }, [src]);
  var loadHandler = function loadHandler() {
    setImageLoading(false);
    return onLoad && onLoad();
  };
  var errorHandler = function errorHandler() {
    setImageLoading(false);
    return onError && onError();
  };
  return React.createElement("figure", {
    className: styles.imageFigure
  }, showLoader && imageLoading && React.createElement(Loader, null), React.createElement("img", _extends({
    src: src,
    alt: alt,
    className: showLoader && imageLoading ? styles.imageFigureLoading : className,
    onError: errorHandler,
    onLoad: loadHandler
  }, rest)), figcaption && React.createElement("figcaption", {
    className: styles.imageFigureCaption
  }, figcaption));
};
Image.propTypes = imagePropTypes;
Image.defaultProps = {
  showLoader: true
};
Image.__docgenInfo = {
  componentName: "Image",
  packageName: "@jutro/components",
  description: "Image",
  displayName: "Image",
  methods: [],
  actualName: "Image",
  metadataType: "element",
  props: {
    src: {
      type: {
        name: "string"
      },
      required: true,
      description: "Specifies the URL of an Image"
    },
    alt: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Specifies an alternate text for an Image"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional img styling class name"
    },
    figcaption: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The caption for the figure"
    },
    width: {
      type: {
        name: "number"
      },
      required: false,
      description: "Specifies the width of an Image"
    },
    onError: {
      type: {
        name: "func"
      },
      required: false,
      description: "Script to be run when an error occurs when the Image is being loaded"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: false,
      description: "Script to be run when Image is loaded succesfully"
    },
    showLoader: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show loader when Image still loading",
      defaultValue: {
        value: "true",
        computed: false
      }
    }
  }
};