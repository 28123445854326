import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useRef, useCallback, useState } from 'react';
import { DATA_TYPE_OBJECT } from '@jutro/prop-types';
import cx from 'classnames';
import { useInitializeIntlPhoneNumber, useSetNumberOnBlur } from './intlPhoneNumberHooks';
import styles from "./IntlPhoneNumberField.module.css";
export var IntlPhoneNumberInput = function IntlPhoneNumberInput(_ref) {
  var className = _ref.className,
    id = _ref.id,
    defaultCountry = _ref.defaultCountry,
    onChange = _ref.onChange,
    dataType = _ref.dataType,
    disabled = _ref.disabled,
    required = _ref.required,
    validateNumber = _ref.validateNumber,
    value = _ref.value,
    placeholder = _ref.placeholder,
    noDropdown = _ref.noDropdown,
    restProps = _objectWithoutProperties(_ref, ["className", "id", "defaultCountry", "onChange", "dataType", "disabled", "required", "validateNumber", "value", "placeholder", "noDropdown"]);
  var inputEl = useRef(null);
  var classes = cx(styles.phoneNumber, className);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setIsFocused = _useState2[1];
  useInitializeIntlPhoneNumber(inputEl, defaultCountry, disabled, placeholder, noDropdown);
  var getInstance = function getInstance() {
    var _window$intlTelInputG;
    return (_window$intlTelInputG = window.intlTelInputGlobals) === null || _window$intlTelInputG === void 0 ? void 0 : _window$intlTelInputG.getInstance(inputEl.current);
  };
  var getCountryDialCode = useCallback(function () {
    var _getInstance;
    return (_getInstance = getInstance()) === null || _getInstance === void 0 ? void 0 : _getInstance.selectedCountryData.dialCode;
  }, []);
  useSetNumberOnBlur(value, getInstance, isFocused, noDropdown);
  var getCountryIsoString = useCallback(function () {
    var _getInstance2;
    return (_getInstance2 = getInstance()) === null || _getInstance2 === void 0 ? void 0 : _getInstance2.selectedCountryData.iso2;
  }, []);
  var getNumber = useCallback(function (format) {
    var _getInstance3;
    return (_getInstance3 = getInstance()) === null || _getInstance3 === void 0 ? void 0 : _getInstance3.getNumber(format);
  }, []);
  var formatValue = useCallback(function () {
    var phoneNumber = getNumber().replace("+".concat(getCountryDialCode()), '');
    if (!phoneNumber) {
      return undefined;
    }
    var countryCode = getCountryDialCode();
    var countryIsoString = getCountryIsoString();
    var resolvedValue = dataType === DATA_TYPE_OBJECT ? {
      countryCode: {
        code: countryIsoString
      },
      phoneNumber: _trimInstanceProperty(phoneNumber).call(phoneNumber)
    } : "+".concat(countryCode).concat(_trimInstanceProperty(phoneNumber).call(phoneNumber));
    return resolvedValue;
  }, [dataType, getCountryDialCode, getNumber, required, getCountryIsoString]);
  var formatIntlTelInput = function formatIntlTelInput() {
    var formattedPhoneInput = getNumber(1);
    if (typeof formattedPhoneInput === 'string') {
      getInstance().setNumber(formattedPhoneInput);
    }
    return formattedPhoneInput;
  };
  var handleChange = function handleChange() {
    onChange(formatValue());
  };
  var handleBlur = function handleBlur() {
    setIsFocused(false);
    formatIntlTelInput();
    var isValid = getInstance().isValidNumber();
    var validationError = getInstance().getValidationError();
    onChange(formatValue());
    if (getInstance().getNumber()) {
      validateNumber(isValid, validationError);
    } else {
      validateNumber(true);
    }
  };
  var handleFocus = function handleFocus() {
    setIsFocused(true);
    validateNumber(true);
  };
  return React.createElement("input", _extends({
    disabled: disabled,
    ref: inputEl,
    className: classes,
    id: id,
    type: "tel",
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
    required: required,
    placeholder: placeholder
  }, restProps));
};
IntlPhoneNumberInput.__docgenInfo = {
  componentName: "IntlPhoneNumberInput",
  packageName: "@jutro/lab-preview-intl-phone-number",
  description: "",
  displayName: "IntlPhoneNumberInput",
  methods: [],
  actualName: "IntlPhoneNumberInput"
};