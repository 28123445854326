import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { IconButton } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { sideContentShape } from '@jutro/prop-types';
import styles from "./IconsBar.module.css";
export var IconsBar = function IconsBar(_ref) {
  var className = _ref.className,
    sides = _ref.sides,
    activeSide = _ref.activeSide,
    onSelection = _ref.onSelection,
    other = _objectWithoutProperties(_ref, ["className", "sides", "activeSide", "onSelection"]);
  var classes = cx(styles.iconsBar, className);
  var translator = useContext(TranslatorContext);
  var getIconClassName = function getIconClassName(index) {
    return cx(_defineProperty({}, styles.activeIcon, index === activeSide));
  };
  return React.createElement(Flex, _extends({
    className: classes,
    role: "tablist",
    direction: "column"
  }, other), _mapInstanceProperty(sides).call(sides, function (_ref2, index) {
    var icon = _ref2.icon,
      label = _ref2.label;
    return React.createElement(IconButton, {
      id: "right-side-icon-tab-".concat(index),
      key: index,
      className: getIconClassName(index),
      onClick: function onClick() {
        return onSelection(index);
      },
      icon: icon,
      role: "tab",
      "aria-controls": "right-side-panel-".concat(index),
      "aria-label": translator(label),
      "aria-selected": index === activeSide
    });
  }));
};
IconsBar.propTypes = {
  className: PropTypes.string,
  sides: PropTypes.arrayOf(sideContentShape).isRequired,
  activeSide: PropTypes.number,
  onSelection: PropTypes.func
};
IconsBar.__docgenInfo = {
  componentName: "IconsBar",
  packageName: "@jutro/router",
  description: "The `IconsBar` component is designed as a container for Icon tabs",
  displayName: "IconsBar",
  methods: [],
  actualName: "IconsBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    sides: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            label: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              description: "Label describing the content used for accessibility",
              required: true
            },
            icon: {
              name: "string",
              description: "Icon that will represent the content on the icon bar",
              required: false
            },
            component: {
              name: "elementType",
              description: "Component to render as content",
              required: true
            },
            componentProps: {
              name: "shape",
              value: {},
              description: "Component props",
              required: false
            }
          }
        }
      },
      required: true,
      description: "Array of contents and icons to display"
    },
    activeSide: {
      type: {
        name: "number"
      },
      required: false,
      description: "Index of the active icon in the array"
    },
    onSelection: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when an icon is clicked on"
    }
  }
};