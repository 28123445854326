import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
export var combineRefs = function combineRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }
  return function (node) {
    _forEachInstanceProperty(refs).call(refs, function (ref) {
      if (!ref) {
        return;
      }
      if (typeof ref === 'function') {
        ref(node);
        return;
      }
      ref.current = node;
    });
  };
};