import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { contextPropType, intlMessageShape } from '@jutro/prop-types';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { DropdownMenuLink } from '../DropdownMenu/DropdownMenuLink';
import { ContextSwitcherButton } from './ContextSwitcherButton';
import styles from "./ContextSwitcher.module.css";
export var isContextActive = function isContextActive(location, _ref) {
  var path = _ref.path,
    exact = _ref.exact,
    match = _ref.match;
  var pathMatches = matchPath(location, {
    path: path,
    exact: exact
  });
  var regexMatches = match ? location.match(match) : false;
  return !!pathMatches || !!regexMatches;
};
export var ContextSwitcher = function ContextSwitcher(_ref2) {
  var className = _ref2.className,
    values = _ref2.values,
    defaultLabel = _ref2.defaultLabel,
    others = _objectWithoutProperties(_ref2, ["className", "values", "defaultLabel"]);
  var location = useLocation();
  var translator = useContext(TranslatorContext);
  var classes = cx(styles.contextSwitcher, className);
  var activeContext = useMemo(function () {
    return _findInstanceProperty(values).call(values, function (context) {
      return isContextActive(location.pathname, context);
    });
  }, [location, values]);
  var renderTrigger = function renderTrigger(_ref3, toggleMenu) {
    var isOpen = _ref3.isOpen,
      menuId = _ref3.menuId,
      other = _objectWithoutProperties(_ref3, ["isOpen", "menuId"]);
    return React.createElement(ContextSwitcherButton, _extends({
      "aria-controls": menuId,
      onClick: toggleMenu
    }, other), React.createElement("span", null, (activeContext === null || activeContext === void 0 ? void 0 : activeContext.title) || translator(defaultLabel)));
  };
  return React.createElement(DropdownMenu, _extends({
    id: "context-switcher",
    className: classes,
    menuClassName: styles.contextSwitcherMenu,
    onRenderTrigger: renderTrigger
  }, others), _mapInstanceProperty(values).call(values, function (context) {
    var path = context.path,
      title = context.title;
    return React.createElement(DropdownMenuLink, {
      to: path,
      activeClassName: styles.activeContext,
      isActive: function isActive() {
        return isContextActive(location.pathname, context);
      }
    }, title);
  }));
};
ContextSwitcher.propTypes = {
  className: PropTypes.string,
  defaultLabel: intlMessageShape,
  values: PropTypes.arrayOf(contextPropType).isRequired
};
ContextSwitcher.__docgenInfo = {
  componentName: "ContextSwitcher",
  packageName: "@jutro/router",
  description: "Context switcher is a route dropdown. It can be used to\nnavigate through high level routes (contexts) in an application.",
  displayName: "ContextSwitcher",
  methods: [],
  actualName: "ContextSwitcher",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class name por the component"
    },
    defaultLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Default button label when no context is active"
    },
    values: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "contextPropType"
        }
      },
      required: true,
      description: "Array of values for the contexts"
    }
  }
};