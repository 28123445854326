import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useContext } from 'react';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import styles from "./ProgressStep.module.css";
import { IconButton } from '../../IconButton/IconButton';
export var ProgressStep = function ProgressStep(_ref) {
  var id = _ref.id,
    step = _ref.step,
    className = _ref.className,
    isVertical = _ref.isVertical;
  var translator = useContext(TranslatorContext);
  var visited = step.visited,
    active = step.active,
    title = step.title,
    onClick = step.onClick,
    _step$disabled = step.disabled,
    disabled = _step$disabled === void 0 ? false : _step$disabled;
  var progressStepClasses = cx(styles.progressStep, className, _defineProperty(_defineProperty(_defineProperty({}, styles.active, active), styles.visited, visited), styles.vertical, isVertical));
  var beforeConnectorClasses = cx(styles.markersConnector, styles.beforeConnector);
  var afterConnectorClasses = cx(styles.markersConnector, styles.afterConnector);
  return React.createElement("div", {
    className: progressStepClasses,
    onClick: onClick,
    onKeyPress: function onKeyPress(e) {
      return e.key === 'Enter' && (onClick === null || onClick === void 0 ? void 0 : onClick(e));
    },
    tabIndex: 0,
    role: "link",
    id: id
  }, React.createElement("div", null, React.createElement("div", {
    className: beforeConnectorClasses
  }), React.createElement(IconButton, {
    tabIndex: -1,
    disabled: disabled,
    className: styles.progressMarker,
    icon: "mi-check",
    iconClassName: styles.progressMarkerIcon
  }), React.createElement("div", {
    className: afterConnectorClasses
  })), React.createElement("div", {
    className: styles.title
  }, title ? translator(title) : ' '));
};
ProgressStep.__docgenInfo = {
  componentName: "ProgressStep",
  packageName: "@jutro/components",
  description: "",
  displayName: "ProgressStep",
  methods: [],
  actualName: "ProgressStep"
};