import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, InlineLabel, ModalNextProvider } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import navBarStyles from "../NavBar/NavBarItem.module.css";
import burgerStyles from "./BurgerMenu.module.css";
import { AppSwitcherModal } from '../AppSwitcher/AppSwitcherModal';
import { BurgerMenuItem } from './BurgerMenuItem';
var burgerAppSwitcherButtonPropTypes = {
  className: PropTypes.string,
  title: intlMessageShape.isRequired,
  icon: PropTypes.string,
  renderAppSwitcher: PropTypes.func
};
export var BurgerAppSwitcherButton = function BurgerAppSwitcherButton(_ref) {
  var title = _ref.title,
    icon = _ref.icon,
    className = _ref.className,
    renderAppSwitcher = _ref.renderAppSwitcher;
  var translator = useContext(TranslatorContext);
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: navBarStyles.navBarItemIcon
  });
  var onClickCallback = useCallback(function () {
    ModalNextProvider.showModal(React.createElement(AppSwitcherModal, {
      title: title,
      renderAppSwitcher: renderAppSwitcher
    }));
  }, [title, renderAppSwitcher]);
  return React.createElement(BurgerMenuItem, {
    tag: "button",
    className: className,
    onClick: onClickCallback
  }, React.createElement(InlineLabel, {
    tag: "div",
    icon: iconComponent,
    className: navBarStyles.titleWrapper
  }, React.createElement("span", {
    className: burgerStyles.burgerAppSwitcherButtonTitle
  }, translator(title))));
};
BurgerAppSwitcherButton.propTypes = burgerAppSwitcherButtonPropTypes;
BurgerAppSwitcherButton.__docgenInfo = {
  componentName: "BurgerAppSwitcherButton",
  packageName: "@jutro/router",
  description: "The `BurgerAppSwitcherButton` component is designed as a container for burgerMenu aooSwitcherAccordion",
  displayName: "BurgerAppSwitcherButton",
  methods: [],
  actualName: "BurgerAppSwitcherButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Button title."
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon to render to the left of the title"
    },
    renderAppSwitcher: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function which takes 'onRouteClick' callback and returns AppSwitcher"
    }
  }
};