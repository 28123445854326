import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext, memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isString from "lodash/isString";
import { JUTRO_TOPICS } from '@jutro/events';
import { intlMessageShape, intlToShape } from '@jutro/prop-types';
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { trackMethod } from '../../helpers';
import { getKeyPressHandler } from '../../accessibility/getKeyPressHandler';
import styles from "./Link.module.css";
import { Icon } from '../Icon/Icon';
import { InlineLabel } from '../InlineLabel/InlineLabel';
var linkPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  to: intlToShape,
  href: intlMessageShape,
  target: PropTypes.string,
  rel: PropTypes.string,
  textClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool
};
var LinkInternal = React.forwardRef(function (props, ref) {
  var children = props.children,
    className = props.className,
    disabled = props.disabled,
    icon = props.icon,
    iconPosition = props.iconPosition,
    inline = props.inline,
    onClick = props.onClick,
    to = props.to,
    href = props.href,
    target = props.target,
    relProp = props.rel,
    textClassName = props.textClassName,
    dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
    exact = props.exact,
    activeClassName = props.activeClassName,
    rest = _objectWithoutProperties(props, ["children", "className", "disabled", "icon", "iconPosition", "inline", "onClick", "to", "href", "target", "rel", "textClassName", "dangerouslySetInnerHTML", "exact", "activeClassName"]);
  var translator = useContext(TranslatorContext);
  var classes = cx(styles.link, _defineProperty(_defineProperty({}, styles.disabled, disabled), styles.inline, inline), className);
  var textClasses = cx(styles.text, textClassName);
  var isRight = iconPosition === 'right';
  var isLeft = !isRight;
  var iconClasses = cx(styles.icon, _defineProperty(_defineProperty({}, styles.left, isLeft), styles.right, isRight));
  var iconComponent = icon && React.createElement(Icon, {
    icon: icon,
    className: iconClasses
  });
  var trackingProps = _objectSpread({}, props);
  if (isString(children)) {
    trackingProps.label = children;
  }
  var trackedOnClick = trackMethod(onClick, JUTRO_TOPICS.LINK_CLICKED, trackingProps);
  var content = React.createElement("span", {
    className: textClasses
  }, translator(children));
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var linkProps = to ? {
    tag: NavLink,
    to: disabled ? '' : urlTranslatorAndSanitizer(to),
    exact: exact,
    activeClassName: activeClassName
  } : {
    tag: 'a',
    href: disabled ? undefined : urlTranslatorAndSanitizer(href),
    target: target,
    rel: target === '_blank' ? 'noreferrer' : relProp
  };
  var labelProps = _objectSpread({
    className: classes,
    onClick: disabled ? undefined : trackedOnClick,
    onKeyPress: getKeyPressHandler(onClick),
    ref: ref,
    icon: iconComponent,
    iconPosition: iconPosition,
    tabIndex: '0',
    'aria-disabled': disabled
  }, linkProps, {}, rest);
  return React.createElement(InlineLabel, labelProps, content);
});
export var Link = memo(LinkInternal);
LinkInternal.propTypes = linkPropTypes;
LinkInternal.defaultProps = {
  disabled: false,
  iconPosition: 'left',
  inline: false
};
LinkInternal.displayName = 'Link';
Link.displayName = 'Link';
Link.__docgenInfo = {
  componentName: "Link",
  packageName: "@jutro/components",
  description: "",
  displayName: "Link",
  methods: [],
  actualName: "Link",
  metadataType: "action",
  props: {
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The children elements to render inside of the Link"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, this link is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional Material Icon name (mi-*)"
    },
    inline: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Indicates whether link located inside of paragraph",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Where the icon is placed relative to the text",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when link is clicked"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when link is clicked.\nUse this for paths internal to the application."
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The destination path when link is clicked.\nUse this for paths external to the application."
    },
    target: {
      type: {
        name: "string"
      },
      required: false,
      description: "Where to display linked URL. Used only for external paths."
    },
    rel: {
      type: {
        name: "string"
      },
      required: false,
      description: "Relationship of the linked URL. Used only for external paths."
    },
    textClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for the link text"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for element when it is active"
    },
    exact: {
      type: {
        name: "bool"
      },
      required: false,
      description: "When true, the activeClassName will only be applied if location is matched exactly"
    }
  }
};