import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { dateRangeValueShape, intlMessageShape, nestedTooltipShape } from '@jutro/prop-types';
import { Grid, useBreakpoint } from '@jutro/layout';
import { DateField } from '@jutro/components';
import { messages } from './DateRangeField.messages';
var dateRangeFieldDefaultProps = _objectSpread({}, DateField.defaultProps, {
  defaultValue: {},
  startPathValueProp: 'startDate',
  endPathValueProp: 'endDate'
});
var dateRangePropTypes = _objectSpread({}, DateField.propTypes, {
  value: dateRangeValueShape,
  defaultValue: dateRangeValueShape,
  startPath: PropTypes.string,
  endPath: PropTypes.string,
  startDataPath: PropTypes.string,
  endDataPath: PropTypes.string,
  startPathValueProp: PropTypes.string,
  endPathValueProp: PropTypes.string,
  endLabel: intlMessageShape,
  endSecondaryLabel: intlMessageShape,
  endTooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  endPlaceholder: intlMessageShape,
  endClassName: PropTypes.string,
  endControlClassName: PropTypes.string,
  endLabelClassName: PropTypes.string
});
export var DateRangeField = function DateRangeField(_ref) {
  var id = _ref.id,
    value = _ref.value,
    _ref$defaultValue = _ref.defaultValue,
    defaultValue = _ref$defaultValue === void 0 ? {} : _ref$defaultValue,
    maxDate = _ref.maxDate,
    minDate = _ref.minDate,
    onValueChange = _ref.onValueChange,
    label = _ref.label,
    path = _ref.path,
    startPath = _ref.startPath,
    endPath = _ref.endPath,
    dataPath = _ref.dataPath,
    startDataPath = _ref.startDataPath,
    endDataPath = _ref.endDataPath,
    startPathValueProp = _ref.startPathValueProp,
    endPathValueProp = _ref.endPathValueProp,
    readOnly = _ref.readOnly,
    labelPosition = _ref.labelPosition,
    endLabel = _ref.endLabel,
    endSecondaryLabel = _ref.endSecondaryLabel,
    endTooltip = _ref.endTooltip,
    endPlaceholder = _ref.endPlaceholder,
    endClassName = _ref.endClassName,
    endControlClassName = _ref.endControlClassName,
    endLabelClassName = _ref.endLabelClassName,
    commonDateProps = _objectWithoutProperties(_ref, ["id", "value", "defaultValue", "maxDate", "minDate", "onValueChange", "label", "path", "startPath", "endPath", "dataPath", "startDataPath", "endDataPath", "startPathValueProp", "endPathValueProp", "readOnly", "labelPosition", "endLabel", "endSecondaryLabel", "endTooltip", "endPlaceholder", "endClassName", "endControlClassName", "endLabelClassName"]);
  var _useState = useState(value || defaultValue),
    _useState2 = _slicedToArray(_useState, 2),
    _useState2$ = _useState2[0],
    startDate = _useState2$.startDate,
    endDate = _useState2$.endDate,
    setRange = _useState2[1];
  var _useBreakpoint = useBreakpoint({}),
    breakpoint = _useBreakpoint.breakpoint;
  var isPhone = breakpoint === 'phone';
  var startDatePath = startPath !== null && startPath !== void 0 ? startPath : "".concat(path, ".").concat(startPathValueProp);
  var endDatePath = endPath !== null && endPath !== void 0 ? endPath : "".concat(path, ".").concat(endPathValueProp);
  var startDateDataPath = startDataPath !== null && startDataPath !== void 0 ? startDataPath : "".concat(dataPath, ".").concat(startPathValueProp);
  var endDateDataPath = endDataPath !== null && endDataPath !== void 0 ? endDataPath : "".concat(dataPath, ".").concat(endPathValueProp);
  var setDateRange = useCallback(function (startDate, endDate, model, props) {
    var endDateChanged = props.endDateChanged;
    var newState = {
      startDate: startDate,
      endDate: endDate
    };
    setRange(newState);
    if (onValueChange) {
      onValueChange(endDateChanged ? endDate : startDate, model, props);
    }
  }, [onValueChange]);
  var setMinDate = useCallback(function (newStartDate, model, props) {
    return setDateRange(newStartDate, endDate, model, _objectSpread({}, props, {
      startDateChanged: true
    }));
  }, [endDate, setDateRange]);
  var setMaxDate = useCallback(function (newEndDate, model, props) {
    return setDateRange(startDate, newEndDate, model, _objectSpread({}, props, {
      endDateChanged: true
    }));
  }, [startDate, setDateRange]);
  var gridColumns = useMemo(function () {
    return labelPosition === 'top' && !isPhone ? ['1fr', '1fr'] : [];
  }, [labelPosition, isPhone]);
  return React.createElement(Grid, {
    columns: gridColumns
  }, React.createElement(DateField, _extends({}, commonDateProps, {
    readOnly: readOnly,
    labelPosition: labelPosition,
    label: label || messages.startLabel,
    id: "".concat(id, "_").concat(startPathValueProp),
    path: startDatePath,
    dataPath: startDateDataPath,
    value: startDate || (value === null || value === void 0 ? void 0 : value.startDate),
    minDate: minDate,
    maxDate: endDate || maxDate,
    onValueChange: setMinDate
  })), React.createElement(DateField, _extends({}, commonDateProps, {
    readOnly: readOnly,
    labelPosition: labelPosition,
    label: endLabel || messages.endLabel,
    secondaryLabel: endSecondaryLabel || commonDateProps.secondaryLabel,
    placeholder: endPlaceholder || commonDateProps.placeholder,
    tooltip: endTooltip || commonDateProps.tooltip,
    className: endClassName || commonDateProps.className,
    controlClassName: endControlClassName || commonDateProps.controlClassName,
    labelClassName: endLabelClassName || commonDateProps.labelClassName,
    id: "".concat(id, "_").concat(endPathValueProp),
    path: endDatePath,
    dataPath: endDateDataPath,
    value: endDate || (value === null || value === void 0 ? void 0 : value.endDate),
    minDate: startDate || minDate,
    maxDate: maxDate,
    onValueChange: setMaxDate
  })));
};
DateRangeField.displayName = 'DateRangeField';
DateRangeField.propTypes = dateRangePropTypes;
DateRangeField.defaultProps = dateRangeFieldDefaultProps;
DateRangeField.__docgenInfo = {
  componentName: "DateRangeField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "DateRangeField",
  methods: [],
  actualName: "DateRangeField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: false,
      description: "Date range value to display if used as controlled component"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateRangeValueShape"
      },
      required: false,
      description: "Default date range value",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    startPath: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path in model to start date"
    },
    endPath: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path in model to end date"
    },
    startDataPath: {
      type: {
        name: "string"
      },
      required: false,
      description: "Start date dataPath"
    },
    endDataPath: {
      type: {
        name: "string"
      },
      required: false,
      description: "End date dataPath"
    },
    startPathValueProp: {
      type: {
        name: "string"
      },
      required: false,
      description: "Name of prop in value object for startPath",
      defaultValue: {
        value: "'startDate'",
        computed: false
      }
    },
    endPathValueProp: {
      type: {
        name: "string"
      },
      required: false,
      description: "Name of prop in value object for endPath",
      defaultValue: {
        value: "'endDate'",
        computed: false
      }
    },
    endLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for end date label"
    },
    endSecondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for end date label (by default same as secondaryLabel prop)"
    },
    endTooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: false,
      description: "Tooltip for end date (by default same as tooltip prop for start date)"
    },
    endPlaceholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for end date placeholder (by default same as placeholder prop)"
    },
    endClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "class for end date (by default takes value from className prop if specified)"
    },
    endControlClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "class for end date control input (by default takes value from controlClassName prop if specified)"
    },
    endLabelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "class for end date label (by default takes value from labelClassName prop if specified)"
    }
  },
  composes: ["@jutro/components"]
};