import { defineMessages } from '@jutro/locale';
export var messages = defineMessages({
  title: {
    "id": "jutro-router.HelpPopover.Title",
    "defaultMessage": "Help and Support"
  },
  viewAll: {
    "id": "jutro-router.HelpPopover.ViewAll",
    "defaultMessage": "View all Help topics"
  }
});