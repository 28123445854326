import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./BurgerMenu.module.css";
import { DropdownMenuSeparator } from '../DropdownMenu/DropdownMenuSeparator';
export var BurgerMenuItem = function BurgerMenuItem(_ref) {
  var id = _ref.id,
    hideSeparator = _ref.hideSeparator,
    separatorClassName = _ref.separatorClassName,
    className = _ref.className,
    Tag = _ref.tag,
    isContainerOnly = _ref.isContainerOnly,
    props = _objectWithoutProperties(_ref, ["id", "hideSeparator", "separatorClassName", "className", "tag", "isContainerOnly"]);
  return React.createElement(React.Fragment, null, !hideSeparator && React.createElement(DropdownMenuSeparator, {
    className: cx(styles.sidebarSeparator, separatorClassName)
  }), React.createElement(Tag, _extends({
    id: id,
    className: cx(styles.sidebarItem, className, _defineProperty({}, styles.sidebarItemInteractive, !isContainerOnly))
  }, props)));
};
BurgerMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  hideSeparator: PropTypes.bool,
  isContainerOnly: PropTypes.bool,
  separatorClassName: PropTypes.string,
  children: PropTypes.node
};
BurgerMenuItem.defaultProps = {
  tag: 'div',
  isContainerOnly: false
};
BurgerMenuItem.__docgenInfo = {
  componentName: "BurgerMenuItem",
  packageName: "@jutro/router",
  description: "BurgerMenuLink renders single burger menu content element as a DropdownMenuLink",
  displayName: "BurgerMenuItem",
  methods: [],
  actualName: "BurgerMenuItem",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Do not render separator if true"
    },
    isContainerOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Disable interaction styles if true",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class to the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The item's content."
    },
    tag: {
      defaultValue: {
        value: "'div'",
        computed: false
      },
      required: false
    }
  }
};