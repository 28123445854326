import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_ASYNC_DATA, SET_LOADING } from './types';
export var reducer = function reducer(state, _ref) {
  var type = _ref.type,
    payload = _objectWithoutProperties(_ref, ["type"]);
  switch (type) {
    case SET_ASYNC_DATA:
      {
        var asyncData = payload.asyncData,
          numberOfRows = payload.numberOfRows;
        return _objectSpread({}, state, {
          asyncData: asyncData,
          numberOfRows: numberOfRows
        });
      }
    case SET_LOADING:
      {
        var loading = payload.loading;
        return _objectSpread({}, state, {
          loading: loading
        });
      }
    default:
      return state;
  }
};