import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { DropdownMenuLink } from '../DropdownMenu/DropdownMenuLink';
import styles from "./AppSwitcher.module.css";
export var AppSwitcherElement = function AppSwitcherElement(_ref) {
  var _ref$item = _ref.item,
    title = _ref$item.title,
    imageUrl = _ref$item.imageUrl,
    imageAlt = _ref$item.imageAlt,
    to = _ref$item.to,
    href = _ref$item.href,
    onClick = _ref$item.onClick,
    onRouteClick = _ref.onRouteClick,
    closePopover = _ref.closePopover,
    resetSearch = _ref.resetSearch;
  var translator = useContext(TranslatorContext);
  var translatedTitle = translator(title);
  var onClickCallback = function onClickCallback(event) {
    if (onClick) {
      onClick(event);
    }
    if (onRouteClick) {
      onRouteClick();
    }
    if (closePopover) {
      closePopover();
    }
    resetSearch();
  };
  return React.createElement(DropdownMenuLink, {
    className: styles.item,
    to: to,
    href: href,
    onClick: onClickCallback
  }, React.createElement("img", {
    src: imageUrl,
    className: styles.itemIcon,
    alt: imageAlt
  }), React.createElement("div", {
    className: styles.itemLabel
  }, translatedTitle));
};
AppSwitcherElement.displayName = 'AppSwitcherElement';
AppSwitcherElement.__docgenInfo = {
  componentName: "AppSwitcherElement",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherElement",
  methods: [],
  actualName: "AppSwitcherElement"
};