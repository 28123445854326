import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Button } from '@jutro/components';
import { Flex, FlexItem, useBreakpoint } from '@jutro/layout';
import { ActionTitleBar } from './ActionTitleBar';
import { TitleElement } from './TitleElement';
import styles from "./ActionTitleBar.module.css";
var ViewModeAction = function ViewModeAction(_ref) {
  var onEditClick = _ref.onEditClick,
    label = _ref.label,
    buttonClassName = _ref.buttonClassName;
  var translator = useContext(TranslatorContext);
  return React.createElement(Button, {
    className: buttonClassName,
    onClick: onEditClick
  }, translator(label));
};
var EditModeAction = function EditModeAction(_ref2) {
  var isSaveEnabled = _ref2.isSaveEnabled,
    labels = _ref2.labels,
    onSaveClick = _ref2.onSaveClick,
    onCancelClick = _ref2.onCancelClick,
    buttonClassName = _ref2.buttonClassName;
  var translator = useContext(TranslatorContext);
  return React.createElement(Flex, {
    gap: "small"
  }, React.createElement(FlexItem, {
    grow: "1"
  }, React.createElement(Button, {
    className: buttonClassName,
    type: "outlined",
    onClick: onCancelClick
  }, translator(labels.cancel))), React.createElement(FlexItem, {
    grow: "1"
  }, React.createElement(Button, {
    className: buttonClassName,
    onClick: onSaveClick,
    disabled: !isSaveEnabled
  }, translator(labels.save))));
};
var EditCancelSaveActions = function EditCancelSaveActions(_ref3) {
  var onEditClick = _ref3.onEditClick,
    onSaveClick = _ref3.onSaveClick,
    onCancelClick = _ref3.onCancelClick,
    isEditMode = _ref3.isEditMode,
    labels = _ref3.labels,
    isSaveEnabled = _ref3.isSaveEnabled,
    internalClassNames = _ref3.internalClassNames;
  if (isEditMode) {
    return React.createElement(EditModeAction, {
      isSaveEnabled: isSaveEnabled,
      labels: labels,
      onSaveClick: onSaveClick,
      onCancelClick: onCancelClick,
      buttonClassName: internalClassNames === null || internalClassNames === void 0 ? void 0 : internalClassNames.button
    });
  }
  return React.createElement(ViewModeAction, {
    onEditClick: onEditClick,
    label: labels.edit,
    buttonClassName: internalClassNames === null || internalClassNames === void 0 ? void 0 : internalClassNames.button
  });
};
export var EditCancelSaveTitleBar = function EditCancelSaveTitleBar(props) {
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps;
  var title = breakpointProps.title,
    _breakpointProps$read = breakpointProps.readOnly,
    readOnly = _breakpointProps$read === void 0 ? false : _breakpointProps$read,
    onEditClick = breakpointProps.onEditClick,
    onSaveClick = breakpointProps.onSaveClick,
    onCancelClick = breakpointProps.onCancelClick,
    isEditMode = breakpointProps.isEditMode,
    isSaveEnabled = breakpointProps.isSaveEnabled,
    labels = breakpointProps.labels,
    internalClassNames = breakpointProps.internalClassNames,
    rest = _objectWithoutProperties(breakpointProps, ["title", "readOnly", "onEditClick", "onSaveClick", "onCancelClick", "isEditMode", "isSaveEnabled", "labels", "internalClassNames"]);
  var translator = useContext(TranslatorContext);
  var actions = function actions() {
    if (readOnly) {
      return null;
    }
    return React.createElement(EditCancelSaveActions, {
      onEditClick: onEditClick,
      onSaveClick: onSaveClick,
      onCancelClick: onCancelClick,
      isEditMode: isEditMode,
      labels: labels,
      isSaveEnabled: isSaveEnabled,
      internalClassNames: internalClassNames
    });
  };
  return React.createElement(ActionTitleBar, rest, React.createElement(TitleElement, null, React.createElement("h4", {
    className: cx(styles.title, internalClassNames === null || internalClassNames === void 0 ? void 0 : internalClassNames.title)
  }, translator(title))), actions());
};
var editCancelSaveTitleBarBasePropTypes = {
  className: PropTypes.string,
  title: intlMessageShape,
  readOnly: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    edit: intlMessageShape.isRequired,
    save: intlMessageShape.isRequired,
    cancel: intlMessageShape.isRequired
  }).isRequired,
  isEditMode: PropTypes.bool,
  isSaveEnabled: PropTypes.bool,
  internalClassNames: PropTypes.shape({
    button: PropTypes.string
  })
};
EditCancelSaveTitleBar.propTypes = _objectSpread({}, editCancelSaveTitleBarBasePropTypes, {
  phone: PropTypes.shape(editCancelSaveTitleBarBasePropTypes),
  phoneWide: PropTypes.shape(editCancelSaveTitleBarBasePropTypes),
  tablet: PropTypes.shape(editCancelSaveTitleBarBasePropTypes)
});
EditCancelSaveTitleBar.displayName = 'EditCancelSaveTitleBar';
ViewModeAction.__docgenInfo = {
  componentName: "ViewModeAction",
  packageName: "@jutro/lab-preview-title-bars",
  description: "",
  displayName: "ViewModeAction",
  methods: [],
  actualName: "ViewModeAction"
};
EditModeAction.__docgenInfo = {
  componentName: "EditModeAction",
  packageName: "@jutro/lab-preview-title-bars",
  description: "",
  displayName: "EditModeAction",
  methods: [],
  actualName: "EditModeAction"
};
EditCancelSaveActions.__docgenInfo = {
  componentName: "EditCancelSaveActions",
  packageName: "@jutro/lab-preview-title-bars",
  description: "",
  displayName: "EditCancelSaveActions",
  methods: [],
  actualName: "EditCancelSaveActions"
};
EditCancelSaveTitleBar.__docgenInfo = {
  componentName: "EditCancelSaveTitleBar",
  packageName: "@jutro/lab-preview-title-bars",
  description: "",
  displayName: "EditCancelSaveTitleBar",
  methods: [],
  actualName: "EditCancelSaveTitleBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Title to render inside title bar"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true action buttons are not rendered"
    },
    onEditClick: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback invoked on edit button click, () => void"
    },
    onSaveClick: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback invoked on save button click, () => void"
    },
    onCancelClick: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback invoked on cancel button click, () => void"
    },
    labels: {
      type: {
        name: "shape",
        value: {
          edit: {
            name: "union",
            required: true,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          save: {
            name: "union",
            required: true,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          cancel: {
            name: "union",
            required: true,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: true,
      description: "Labels for rendered buttons"
    },
    isEditMode: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true save and cancel buttons will be rendered"
    },
    isSaveEnabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Enables the save button"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          button: {
            name: "string",
            required: false
          }
        }
      },
      required: false,
      description: "ClassNames for internal components"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: false
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: true
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: true
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: true
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: true
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: false
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: false
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: false
              }
            },
            description: "ClassNames for internal components",
            required: false
          }
        }
      },
      required: false,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: false
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: true
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: true
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: true
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: true
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: false
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: false
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: false
              }
            },
            description: "ClassNames for internal components",
            required: false
          }
        }
      },
      required: false,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          title: {
            name: "union",
            description: "Title to render inside title bar",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          readOnly: {
            name: "bool",
            description: "If true action buttons are not rendered",
            required: false
          },
          onEditClick: {
            name: "func",
            description: "Callback invoked on edit button click, () => void",
            required: true
          },
          onSaveClick: {
            name: "func",
            description: "Callback invoked on save button click, () => void",
            required: true
          },
          onCancelClick: {
            name: "func",
            description: "Callback invoked on cancel button click, () => void",
            required: true
          },
          labels: {
            name: "shape",
            value: {
              edit: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              save: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              },
              cancel: {
                name: "union",
                required: true,
                value: [{
                  name: "string"
                }, {
                  name: "shape",
                  value: {
                    id: {
                      name: "string",
                      required: false
                    },
                    defaultMessage: {
                      name: "string",
                      required: false
                    },
                    args: {
                      name: "shape",
                      value: {},
                      required: false
                    }
                  }
                }]
              }
            },
            description: "Labels for rendered buttons",
            required: true
          },
          isEditMode: {
            name: "bool",
            description: "If true save and cancel buttons will be rendered",
            required: false
          },
          isSaveEnabled: {
            name: "bool",
            description: "Enables the save button",
            required: false
          },
          internalClassNames: {
            name: "shape",
            value: {
              button: {
                name: "string",
                required: false
              }
            },
            description: "ClassNames for internal components",
            required: false
          }
        }
      },
      required: false,
      description: ""
    }
  }
};