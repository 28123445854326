import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import { useEffect, useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';
import { countryMessages } from './IntlPhoneNumberField.messages';
export var useInitializeIntlPhoneNumber = function useInitializeIntlPhoneNumber(inputEl, defaultCountry, disabled, placeholder, noDropdown) {
  var translator = useContext(TranslatorContext);
  var localizedCountries = useMemo(function () {
    var localizedCountriesKeys = _Object$keys(countryMessages);
    return _reduceInstanceProperty(localizedCountriesKeys).call(localizedCountriesKeys, function (obj, key) {
      return _objectSpread({}, obj, _defineProperty({}, key, translator(countryMessages[key])));
    }, {});
  }, [countryMessages]);
  useEffect(function () {
    if (!inputEl.current) return undefined;
    var intlPhoneNumberEl = intlTelInput(inputEl.current, {
      initialCountry: defaultCountry,
      preferredCountries: [defaultCountry],
      separateDialCode: !noDropdown,
      autoHideDialCode: !noDropdown,
      allowDropdown: !disabled,
      nationalMode: false,
      formatOnDisplay: true,
      localizedCountries: localizedCountries,
      autoPlaceholder: placeholder ? 'polite' : 'aggressive'
    });
    if (intlPhoneNumberEl) {
      var el = document.getElementsByClassName('iti__selected-dial-code')[0];
      el === null || el === void 0 ? void 0 : el.setAttribute('role', 'textbox');
      el === null || el === void 0 ? void 0 : el.setAttribute('aria-label', 'selected country code');
      el === null || el === void 0 ? void 0 : el.setAttribute('title', 'country code textbox');
    }
    return function () {
      intlPhoneNumberEl.destroy();
      if (inputEl.current) {
        inputEl.current.style.paddingLeft = null;
      }
    };
  }, [defaultCountry, disabled, inputEl, placeholder, noDropdown]);
};
export var useSetNumberOnBlur = function useSetNumberOnBlur(value, intlPhoneNumberInstance, isFocused, noDropdown) {
  useEffect(function () {
    if (!intlPhoneNumberInstance()) {
      return;
    }
    if (!isFocused) {
      if (typeof value === 'object') {
        var _value$countryCode;
        if ((_value$countryCode = value.countryCode) !== null && _value$countryCode !== void 0 && _value$countryCode.code) intlPhoneNumberInstance().setCountry(value.countryCode.code);
        if (!noDropdown && value !== null && value !== void 0 && value.phoneNumber) intlPhoneNumberInstance().setNumber(value.phoneNumber);
      } else if (value) {
        intlPhoneNumberInstance().setNumber(value);
      }
    }
  }, [intlPhoneNumberInstance, isFocused, value]);
};