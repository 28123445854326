import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';
import _ from 'lodash';
import cx from 'classnames';
import { Accordion, AccordionCard, Chevron, Icon } from '@jutro/components';
import { TranslatorContext, useSafeTranslatedUrls } from '@jutro/locale';
import { intlMessageShape, IntlMessageShape, routesShape } from '@jutro/prop-types';
import { getIdFromPath } from '../../NavBarLink';
import { SideNavigationContext } from '../SideNavigationContext';
import { NavigationContent } from '../../NavigationContent';
import { renderBadge } from '../renderBadge';
import styles from "../SideNavigation.module.css";
import navBarStyles from "../../NavBarItem.module.css";
export var getNotifications = function getNotifications(routes) {
  return _reduceInstanceProperty(routes).call(routes, function (notifications, route) {
    return notifications + _.get(route, 'navLink.notifications', 0);
  }, 0);
};
export var NavBarAccordionInternal = function NavBarAccordionInternal(_ref) {
  var toProp = _ref.to,
    header = _ref.header,
    icon = _ref.icon,
    disabled = _ref.disabled,
    className = _ref.className,
    routes = _ref.routes,
    loc = _ref.location,
    notifications = _ref.notifications;
  var _useContext = useContext(SideNavigationContext),
    activeRoute = _useContext.activeRoute,
    setActiveRoute = _useContext.setActiveRoute,
    isSideNavigationCollapsed = _useContext.isCollapsed,
    expandSideNavigation = _useContext.expand;
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var to = urlTranslatorAndSanitizer(toProp);
  var hasActiveRoute = _someInstanceProperty(routes).call(routes, function (_ref2) {
    var path = _ref2.path;
    return matchPath(loc.pathname, path);
  });
  var cardID = getIdFromPath(to) || 'unknownId';
  var getHeader = function getHeader(isOpen) {
    var iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: navBarStyles.navBarItemIcon
    });
    var subNavsNotifications = getNotifications(routes);
    var classes = cx(navBarStyles.navBarItem, navBarStyles.leftNavBarItem, _defineProperty(_defineProperty({}, styles.activeAccordion, hasActiveRoute), styles.expandedAccordion, isOpen), className);
    var chevronClasses = cx(styles.chevron, _defineProperty(_defineProperty({}, styles.rightAlignChevron, !notifications), styles.openedAccordion, isOpen));
    return React.createElement("button", {
      tabIndex: "-1",
      className: classes,
      onClick: function onClick() {
        if (isSideNavigationCollapsed) {
          expandSideNavigation();
        }
        setActiveRoute(to);
      }
    }, React.createElement("div", {
      className: navBarStyles.titleWrapper
    }, iconComponent, React.createElement("div", {
      className: navBarStyles.titleContentWrapper
    }, React.createElement("span", {
      className: navBarStyles.title
    }, translator(header)), renderBadge(notifications, "badge__".concat(cardID), notifications || subNavsNotifications), React.createElement(Chevron, {
      className: chevronClasses,
      chevronIcon: "mi-expand-more",
      isOpen: isOpen
    }))));
  };
  var classes = cx(styles.subItem, _defineProperty({}, styles.subItemWithIcon, icon));
  var styledRoutes = _mapInstanceProperty(routes).call(routes, function (_ref3) {
    var navLink = _ref3.navLink,
      rest = _objectWithoutProperties(_ref3, ["navLink"]);
    return _objectSpread({}, rest, {
      navLink: _objectSpread({}, navLink, {
        className: cx(navLink && navLink.className, classes)
      })
    });
  });
  var shouldBeCollapsed = activeRoute !== to && !hasActiveRoute || isSideNavigationCollapsed;
  return React.createElement(Accordion, _extends({
    disabled: disabled,
    showFrame: false,
    closeOthers: false,
    className: styles.sideNavAccordion
  }, shouldBeCollapsed && {
    accordionStates: []
  }), React.createElement(AccordionCard, {
    id: cardID,
    cardBodyClassName: styles.cardBody,
    cardTitleClassName: styles.cardTitle,
    cardHeadingClassName: styles.cardHeading,
    collapseClassName: styles.accordionCollapse,
    renderHeader: getHeader
  }, React.createElement(NavigationContent, {
    routes: styledRoutes,
    alignment: "left"
  })));
};
NavBarAccordionInternal.propTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  to: intlMessageShape.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool,
  routes: routesShape
};
NavBarAccordionInternal.displayName = 'NavBarAccordion';
export var NavBarAccordion = withRouter(NavBarAccordionInternal);
NavBarAccordionInternal.__docgenInfo = {
  componentName: "NavBarAccordion",
  packageName: "@jutro/router",
  description: "The `NavBarAccordion` component is designed as a container for sub nav links of side navigation",
  displayName: "NavBarAccordion",
  methods: [],
  actualName: "NavBarAccordionInternal",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Link header."
    },
    to: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Internal path to the application."
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag to make item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for  active component."
    },
    exact: {
      type: {
        name: "bool"
      },
      required: false,
      description: "When true, will only match if the path matches the location.pathname exactly"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: false,
      description: "Sub navigation items metadata"
    }
  }
};