import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Symbol$toPrimitive from "@babel/runtime-corejs3/core-js-stable/symbol/to-primitive";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[_Symbol$toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '@jutro/platform';
import { TranslatorContext } from '@jutro/locale';
import { Button, Badge, InputField, Collapse } from '@jutro/components';
import cx from 'classnames';
import internalStyles from "./FilterBar.module.css";
import { messages } from './FilterBar.messages';
import { FilterBarContent } from './FilterBarContent';
var filterBarPropTypes = {
  uiProps: PropTypes.object,
  classNameMap: PropTypes.object,
  componentMap: PropTypes.object,
  callbackMap: PropTypes.object,
  onFiltersChange: PropTypes.func.isRequired,
  initialFilters: PropTypes.object,
  searchKey: PropTypes.string
};
export var DEFAULT_SEARCH_KEY = 'search';
export var FilterBar = function FilterBar(_ref) {
  var callbackMap = _ref.callbackMap,
    componentMap = _ref.componentMap,
    initialFiltersProp = _ref.initialFilters,
    onFiltersChangeProp = _ref.onFiltersChange,
    metadata = _ref.uiProps,
    searchKey = _ref.searchKey,
    styles = _ref.styles;
  var _context;
  var initialSearch = initialFiltersProp[searchKey],
    initialFilters = _objectWithoutProperties(initialFiltersProp, _mapInstanceProperty(_context = [searchKey]).call(_context, _toPropertyKey));
  var _useState = useState(initialSearch),
    _useState2 = _slicedToArray(_useState, 2),
    search = _useState2[0],
    setSearch = _useState2[1];
  var searchDebounced = useDebounce(search, 300);
  var _useState3 = useState(initialFilters),
    _useState4 = _slicedToArray(_useState3, 2),
    filters = _useState4[0],
    setFilters = _useState4[1];
  var onFiltersChangeRef = useRef(null);
  var searchKeyRef = useRef(searchKey);
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    isShown = _useState6[0],
    setShown = _useState6[1];
  var translator = useContext(TranslatorContext);
  var numberFiltersApplied = _Object$keys(filters).length;
  var isFiltersEmpty = numberFiltersApplied === 0;
  var toggleFilterBar = useCallback(function () {
    setShown(!isShown);
  }, [isShown]);
  useEffect(function () {
    if (onFiltersChangeRef.current) {
      var newFilters = _objectSpread({}, filters);
      if (searchDebounced) {
        newFilters[searchKeyRef.current] = searchDebounced;
      }
      onFiltersChangeRef.current(newFilters);
    }
  }, [searchDebounced, filters]);
  useEffect(function () {
    onFiltersChangeRef.current = onFiltersChangeProp;
  }, [onFiltersChangeProp]);
  var filterBarContentProps = {
    styles: styles,
    metadata: metadata,
    onFiltersChange: setFilters,
    componentMap: componentMap,
    callbackMap: callbackMap,
    isFiltersEmpty: isFiltersEmpty,
    initialFilters: filters
  };
  var manageFiltersButtonClassNames = cx(internalStyles.manageFiltersButton, _defineProperty({}, internalStyles.manageFiltersButtonSelected, isShown));
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: internalStyles.searchBar
  }, React.createElement(InputField, {
    id: "searchFilterBar",
    icon: "mi-search",
    iconPosition: "right",
    value: search,
    onValueChange: setSearch,
    placeholder: translator(messages.searchInput),
    controlClassName: internalStyles.searchField
  }), metadata && React.createElement(Button, {
    id: "manageFilters",
    icon: "mi-tune",
    type: "outlined",
    onClick: toggleFilterBar,
    className: manageFiltersButtonClassNames
  }, translator(messages.manageFilters), !isFiltersEmpty && React.createElement(Badge, {
    id: "filtersCount",
    type: "info",
    value: numberFiltersApplied,
    className: internalStyles.manageFiltersBadge
  }))), metadata && React.createElement(Collapse, {
    isOpen: isShown
  }, React.createElement(FilterBarContent, filterBarContentProps)));
};
FilterBar.propTypes = filterBarPropTypes;
FilterBar.defaultProps = {
  initialFilters: {},
  searchKey: DEFAULT_SEARCH_KEY
};
FilterBar.__docgenInfo = {
  componentName: "FilterBar",
  packageName: "@jutro/datatable",
  description: "Component for filters rendering, supplied through metadata",
  displayName: "FilterBar",
  methods: [],
  actualName: "FilterBar",
  metadataType: "container",
  props: {
    uiProps: {
      type: {
        name: "object"
      },
      required: false,
      description: "Content metadata or an array of metadata"
    },
    classNameMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve class name to hashed class name"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve component map"
    },
    callbackMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve callback name to callback function"
    },
    onFiltersChange: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback for when filters are changed"
    },
    initialFilters: {
      type: {
        name: "object"
      },
      required: false,
      description: "Object with initial filters to be applied",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    searchKey: {
      type: {
        name: "string"
      },
      required: false,
      description: "Name of search filter key in filters data. Can not change during component lifetime",
      defaultValue: {
        value: "'search'",
        computed: false
      }
    }
  }
};