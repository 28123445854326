import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { FormattedNumber } from '../../values/FormattedNumber';
import styles from "./SimpleProgressBar.module.css";
var simpleProgressBarPropTypes = {
  id: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
  progressBarClassName: PropTypes.string,
  stepClassName: PropTypes.string,
  label: intlMessageShape,
  labelPosition: PropTypes.oneOf(['top', 'bottom']),
  showProgressLabel: PropTypes.bool,
  showPercentageProgress: PropTypes.bool
};
export var SimpleProgressBar = function SimpleProgressBar(_ref) {
  var path = _ref.path,
    completed = _ref.completed,
    value = _ref.value,
    total = _ref.total,
    className = _ref.className,
    progressBarClassName = _ref.progressBarClassName,
    stepClassName = _ref.stepClassName,
    label = _ref.label,
    labelPosition = _ref.labelPosition,
    showProgressLabel = _ref.showProgressLabel,
    showPercentageProgress = _ref.showPercentageProgress,
    rest = _objectWithoutProperties(_ref, ["path", "completed", "value", "total", "className", "progressBarClassName", "stepClassName", "label", "labelPosition", "showProgressLabel", "showPercentageProgress"]);
  var translator = useContext(TranslatorContext);
  var componentClassNames = cx(styles.simpleProgressBar, className);
  var progressBarClassNames = cx(styles.progressBar, progressBarClassName);
  var progressBarStepClassNames = cx(styles.progressBarSteps, stepClassName);
  var effectiveCompleted = path ? value : completed;
  var progress = (effectiveCompleted / total * 100).toFixed();
  progress = progress < 100 ? progress : 100;
  var labelPositioningStyles = label && showProgressLabel && labelPosition === 'top' ? styles.labelContainer : styles.labelContainerCenter;
  var progressLabel = showPercentageProgress ? React.createElement(FormattedNumber, {
    format: "percent",
    value: progress / 100
  }) : "".concat(effectiveCompleted, "/").concat(total);
  var renderLabel = function renderLabel() {
    return React.createElement("div", {
      className: styles.label
    }, translator(label));
  };
  return React.createElement("div", _extends({
    className: componentClassNames
  }, rest), React.createElement("div", {
    className: labelPositioningStyles
  }, label && labelPosition === 'top' ? renderLabel() : null, showProgressLabel && React.createElement("div", {
    className: styles.progressLabel
  }, progressLabel)), React.createElement("div", {
    className: progressBarClassNames,
    role: "progressbar",
    "aria-valuetext": label,
    "aria-valuenow": progress,
    "aria-valuemin": "0",
    "aria-valuemax": "100"
  }, React.createElement("div", {
    className: progressBarStepClassNames,
    style: {
      transform: "translateX(".concat(progress - 100, "%)")
    }
  })), React.createElement("div", {
    className: labelPositioningStyles
  }, label && labelPosition === 'bottom' ? renderLabel() : null));
};
SimpleProgressBar.defaultProps = {
  labelPosition: 'bottom',
  showProgressLabel: true,
  showPercentageProgress: false
};
SimpleProgressBar.propTypes = simpleProgressBarPropTypes;
SimpleProgressBar.__docgenInfo = {
  componentName: "SimpleProgressBar",
  packageName: "@jutro/components",
  description: "SimpleProgressBar",
  displayName: "SimpleProgressBar",
  methods: [],
  actualName: "SimpleProgressBar",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "A unique ID of this element"
    },
    completed: {
      type: {
        name: "number"
      },
      required: true,
      description: "Number of steps that were completed"
    },
    total: {
      type: {
        name: "number"
      },
      required: true,
      description: "Total number of steps"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to override whole component styles"
    },
    progressBarClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to override progress bar styles"
    },
    stepClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to override progress bar step styles"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Label of the progress bar"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'bottom'",
          computed: false
        }]
      },
      required: false,
      description: "Allows you to select position of the progress bar label",
      defaultValue: {
        value: "'bottom'",
        computed: false
      }
    },
    showProgressLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Shows progress label",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    showPercentageProgress: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true shows percentage progress instead of fractions",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};