import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
export var GenericSelectControlControl = function GenericSelectControlControl(_ref) {
  var children = _ref.children,
    isFocused = _ref.isFocused,
    innerRef = _ref.innerRef,
    innerProps = _ref.innerProps,
    className = _ref.className,
    _ref$selectProps = _ref.selectProps,
    genericSelectStyles = _ref$selectProps.genericSelectStyles,
    invalid = _ref$selectProps.invalid;
  var controlClasses = cx(genericSelectStyles.control, _defineProperty(_defineProperty(_defineProperty({}, genericSelectStyles.controlFocused, isFocused && !invalid), "invalid", invalid), genericSelectStyles.focusedInvalid, isFocused && invalid), className);
  return React.createElement("div", _extends({
    ref: innerRef,
    className: controlClasses
  }, innerProps), children);
};
GenericSelectControlControl.__docgenInfo = {
  componentName: "GenericSelectControlControl",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlControl",
  methods: [],
  actualName: "GenericSelectControlControl"
};