import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useContext, useRef } from 'react';
import { useKeyActive } from '@jutro/platform';
import { isEmptyValue, isNilValue } from '@jutro/data';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import { defaultAvailableValuePropType } from '@jutro/prop-types';
import { getOptionCode, getOptionName } from '../availableValues';
var toggleButtonPropTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
  option: PropTypes.object,
  styleClasses: PropTypes.string,
  active: PropTypes.string,
  activeKeypress: PropTypes.string,
  uniqueId: PropTypes.string,
  value: defaultAvailableValuePropType,
  handleButtonClick: PropTypes.func
};
export var ToggleButton = function ToggleButton(_ref) {
  var id = _ref.id,
    disabled = _ref.disabled,
    option = _ref.option,
    styleClasses = _ref.styleClasses,
    active = _ref.active,
    activeKeypress = _ref.activeKeypress,
    uniqueId = _ref.uniqueId,
    value = _ref.value,
    handleButtonClick = _ref.handleButtonClick;
  var translator = useContext(TranslatorContext);
  var code = getOptionCode(option);
  var name = getOptionName(option);
  var selectedValue = value;
  var checked = !isEmptyValue(selectedValue) && selectedValue.toString() === code.toString();
  if (isNilValue(code)) {
    code = 'empty';
    checked = isEmptyValue(selectedValue);
  }
  var ref = useRef();
  var isActiveKeyPressed = useKeyActive(ref);
  var buttonClasses = cx(_defineProperty({}, activeKeypress, isActiveKeyPressed), styleClasses, _defineProperty({}, active, checked));
  var valueId = "".concat(id, "_").concat(code);
  return React.createElement("button", {
    type: "button",
    checked: checked,
    "aria-pressed": checked,
    key: valueId,
    className: buttonClasses,
    onClick: handleButtonClick,
    "data-value": code.toString() || '',
    disabled: disabled,
    "aria-labelledby": uniqueId,
    ref: ref
  }, translator(name));
};
ToggleButton.propTypes = toggleButtonPropTypes;
ToggleButton.__docgenInfo = {
  componentName: "ToggleButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "ToggleButton",
  methods: [],
  actualName: "ToggleButton",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "id"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "disabled flag"
    },
    theme: {
      type: {
        name: "object"
      },
      required: false,
      description: "theme"
    },
    option: {
      type: {
        name: "object"
      },
      required: false,
      description: "object that contains the value for the button"
    },
    styleClasses: {
      type: {
        name: "string"
      },
      required: false,
      description: "class name of button styles"
    },
    active: {
      type: {
        name: "string"
      },
      required: false,
      description: "active - class name object of button active styles"
    },
    activeKeypress: {
      type: {
        name: "string"
      },
      required: false,
      description: "activeKeypress - class name object of button activeKeypress styles"
    },
    uniqueId: {
      type: {
        name: "string"
      },
      required: false,
      description: "uniqueId - uniqueIdentifier"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "value - selectedValue"
    },
    handleButtonClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "handleButtonClick function"
    }
  }
};