import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape, tooltipShape } from '@jutro/prop-types';
import cx from 'classnames';
import { uniqueInnerId, useKeyActive } from '@jutro/platform';
import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipContent } from '../Tooltip/TooltipContent';
import { messages } from './IconButton.messages';
import styles from "./IconButton.module.css";
export var availableIconColors = ['light', 'neutral', 'dark'];
var iconButtonPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  ariaLabel: intlMessageShape,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.oneOf(availableIconColors),
  disabled: PropTypes.bool,
  tooltip: tooltipShape
};
export var IconButton = React.forwardRef(function (_ref, ref) {
  var onClick = _ref.onClick,
    onKeyPress = _ref.onKeyPress,
    onKeyDown = _ref.onKeyDown,
    onMouseDown = _ref.onMouseDown,
    className = _ref.className,
    iconClassName = _ref.iconClassName,
    ariaLabel = _ref.ariaLabel,
    disabled = _ref.disabled,
    icon = _ref.icon,
    iconColor = _ref.iconColor,
    dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
    tooltip = _ref.tooltip,
    rest = _objectWithoutProperties(_ref, ["onClick", "onKeyPress", "onKeyDown", "onMouseDown", "className", "iconClassName", "ariaLabel", "disabled", "icon", "iconColor", "dangerouslySetInnerHTML", "tooltip"]);
  var translator = useContext(TranslatorContext);
  var newRef = ref || useRef();
  var isActiveKeyPressed = useKeyActive(newRef);
  var iconColorClass = styles[iconColor];
  var classes = cx(_defineProperty({}, styles.activeKeypress, isActiveKeyPressed), styles.iconButton, iconColorClass, className);
  var iconClasses = cx(styles.icon, iconColorClass, iconClassName);
  var buttonElement = React.createElement("button", _extends({
    type: "button",
    className: classes,
    onClick: onClick,
    onKeyPress: onKeyPress,
    onKeyDown: onKeyDown,
    onMouseDown: onMouseDown,
    "aria-label": translator(ariaLabel || messages.iconButtonText),
    tabIndex: "0",
    disabled: disabled,
    ref: newRef
  }, rest), React.createElement(Icon, {
    className: iconClasses,
    icon: icon,
    "aria-hidden": "true"
  }));
  if (tooltip && !!(tooltip.title || tooltip.text || tooltip.renderContent)) {
    var text = tooltip.text,
      title = tooltip.title,
      link = tooltip.link,
      href = tooltip.href,
      renderContent = tooltip.renderContent,
      placement = tooltip.placement;
    var content = renderContent ? renderContent() : React.createElement(TooltipContent, {
      title: title,
      text: text,
      href: href,
      link: link,
      translator: translator
    });
    var _uniqueInnerId = uniqueInnerId('icon-button-tooltip', 'tooltipId'),
      tooltipId = _uniqueInnerId.tooltipId;
    var tooltipProps = {
      id: tooltipId,
      content: content,
      placement: placement,
      trigger: 'mouseenter focus'
    };
    return React.createElement(Tooltip, tooltipProps, buttonElement);
  }
  return buttonElement;
});
IconButton.displayName = 'IconButton';
IconButton.propTypes = iconButtonPropTypes;
IconButton.defaultProps = {
  disabled: false,
  iconColor: 'dark'
};
IconButton.__docgenInfo = {
  componentName: "IconButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "IconButton",
  methods: [],
  actualName: "IconButton",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify this component."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for icon."
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "On click handler"
    },
    onMouseDown: {
      type: {
        name: "func"
      },
      required: false,
      description: "On mouse down handler"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: false,
      description: "On key down handler"
    },
    onKeyPress: {
      type: {
        name: "func"
      },
      required: false,
      description: "On key press handler"
    },
    ariaLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Aria label text"
    },
    icon: {
      type: {
        name: "string"
      },
      required: true,
      description: "Material Icon name (mi-*)"
    },
    iconColor: {
      type: {
        name: "enum",
        value: [{
          value: "'light'",
          computed: false
        }, {
          value: "'neutral'",
          computed: false
        }, {
          value: "'dark'",
          computed: false
        }]
      },
      required: false,
      description: "The color of the icon",
      defaultValue: {
        value: "'dark'",
        computed: false
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, this button is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    tooltip: {
      type: {
        name: "shape",
        value: {
          placement: {
            name: "enum",
            value: [{
              value: "'top'",
              computed: false
            }, {
              value: "'top-start'",
              computed: false
            }, {
              value: "'top-end'",
              computed: false
            }, {
              value: "'right'",
              computed: false
            }, {
              value: "'right-start'",
              computed: false
            }, {
              value: "'right-end'",
              computed: false
            }, {
              value: "'bottom'",
              computed: false
            }, {
              value: "'bottom-start'",
              computed: false
            }, {
              value: "'bottom-end'",
              computed: false
            }, {
              value: "'left'",
              computed: false
            }, {
              value: "'left-start'",
              computed: false
            }, {
              value: "'left-end'",
              computed: false
            }, {
              value: "'auto'",
              computed: false
            }, {
              value: "'auto-start'",
              computed: false
            }, {
              value: "'auto-end'",
              computed: false
            }],
            description: "Positions of the tooltip relative to its reference element",
            required: false
          }
        }
      },
      required: false,
      description: "IconButton tooltip"
    }
  }
};