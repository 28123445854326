import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import PropTypes from 'prop-types';
import { dropdownMenuLinkPropTypes } from './dropdownMenuLinkPropTypes';
import { availableValuesIdDisplayNameObject } from './availableValuePropTypes';
import { intlMessageShape } from './intlMessageShape';
import { appSwitcherItemsObject } from './appSwitcherRoutePropTypes';
import { linkShape } from './linkShape';
import { avatarShape } from './avatarPropTypes';
var lookupOptionShape = PropTypes.shape(_objectSpread({}, availableValuesIdDisplayNameObject, {
  type: PropTypes.string.isRequired
}));
var dropdownMenuLinkShape = PropTypes.shape(_objectSpread({}, dropdownMenuLinkPropTypes, {
  title: intlMessageShape
}));
export var applicationHeaderPropTypes = {
  className: PropTypes.string,
  logoSrc: PropTypes.string,
  logoUrl: PropTypes.string,
  logoTitle: intlMessageShape,
  onSearchValueChange: PropTypes.func,
  onLoadValues: PropTypes.func,
  searchAvailableValues: PropTypes.arrayOf(lookupOptionShape.isRequired),
  showNotifications: PropTypes.bool,
  notificationChildren: PropTypes.node,
  showAvatar: PropTypes.bool,
  avatarChildren: PropTypes.node,
  avatarProps: avatarShape,
  commonAvatarRoutes: PropTypes.arrayOf(dropdownMenuLinkShape.isRequired),
  searchFieldPlaceholder: intlMessageShape,
  showAppSwitcher: PropTypes.bool,
  appSwitcherItems: appSwitcherItemsObject,
  useAuthInfo: PropTypes.bool,
  showHelp: PropTypes.bool,
  helpUrl: PropTypes.oneOfType([intlMessageShape, linkShape]),
  helpPopoverItems: PropTypes.arrayOf(PropTypes.object),
  callbackMap: PropTypes.shape({}),
  appSwitcherFooterText: intlMessageShape,
  appSwitcherFooterUrl: intlMessageShape,
  onAppSwitcherFooterClick: PropTypes.func,
  appSwitcherSearchEnabled: PropTypes.bool,
  appSwitcherCollapsibleGroupsThreshold: PropTypes.number,
  appSwitcherHideFooter: PropTypes.bool
};