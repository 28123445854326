import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import styles from "./ModalNext.module.css";
import { getLayoutComponent } from './helpers/modalHelpers';
import { layoutShape } from '../../layouts/Layout';
var defaultLayout = {
  component: 'Grid',
  componentProps: {
    rows: ['auto', '1fr', 'auto'],
    vgap: 'none'
  }
};
var modalNextPropTypes = {
  contentLayout: PropTypes.oneOfType([PropTypes.shape({
    component: PropTypes.string.isRequired,
    componentProps: PropTypes.object
  }), layoutShape]),
  isOpen: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onAfterClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  closeTimeoutMS: PropTypes.number,
  contentLabel: intlMessageShape,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  shouldFocusAfterRender: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldReturnFocusAfterClose: PropTypes.bool,
  parentSelector: PropTypes.func,
  ariaLabelledby: PropTypes.string,
  ariaDescribedby: PropTypes.string
};
export var ModalNext = function ModalNext(_ref) {
  var _ref$contentLayout = _ref.contentLayout,
    contentLayout = _ref$contentLayout === void 0 ? defaultLayout : _ref$contentLayout,
    isOpen = _ref.isOpen,
    onAfterOpen = _ref.onAfterOpen,
    onAfterClose = _ref.onAfterClose,
    onRequestClose = _ref.onRequestClose,
    closeTimeoutMS = _ref.closeTimeoutMS,
    contentLabel = _ref.contentLabel,
    overlayClassName = _ref.overlayClassName,
    className = _ref.className,
    shouldFocusAfterRender = _ref.shouldFocusAfterRender,
    shouldCloseOnOverlayClick = _ref.shouldCloseOnOverlayClick,
    shouldCloseOnEsc = _ref.shouldCloseOnEsc,
    shouldReturnFocusAfterClose = _ref.shouldReturnFocusAfterClose,
    parentSelector = _ref.parentSelector,
    ariaLabelledby = _ref.ariaLabelledby,
    ariaDescribedby = _ref.ariaDescribedby,
    children = _ref.children;
  var Layout = getLayoutComponent(contentLayout.component);
  var _contentLayout$compon = contentLayout.componentProps,
    dangerouslySetInnerHTML = _contentLayout$compon.dangerouslySetInnerHTML,
    layoutProps = _objectWithoutProperties(_contentLayout$compon, ["dangerouslySetInnerHTML"]);
  var translator = useContext(TranslatorContext);
  var modalClasses = cx(styles.modal, className);
  var overlayClasses = cx(styles.overlay, overlayClassName);
  var modalLayout = cx(styles.modalLayout, layoutProps.className);
  return React.createElement(ReactModal, {
    isOpen: isOpen,
    onAfterOpen: onAfterOpen,
    onAfterClose: onAfterClose !== null && onAfterClose !== void 0 ? onAfterClose : ModalNext.handleAfterClose,
    onRequestClose: onRequestClose,
    closeTimeoutMS: closeTimeoutMS,
    contentLabel: translator(contentLabel),
    overlayClassName: overlayClasses,
    className: modalClasses,
    shouldFocusAfterRender: shouldFocusAfterRender,
    shouldCloseOnOverlayClick: shouldCloseOnOverlayClick,
    shouldCloseOnEsc: shouldCloseOnEsc,
    shouldReturnFocusAfterClose: shouldReturnFocusAfterClose,
    parentSelector: parentSelector,
    aria: {
      modal: 'true',
      labelledby: ariaLabelledby,
      describedby: ariaDescribedby
    }
  }, React.createElement(Layout, _extends({}, layoutProps, {
    className: modalLayout
  }), children));
};
ModalNext.handleAfterClose = null;
ModalNext.propTypes = modalNextPropTypes;
ModalNext.defaultProps = {
  contentLayout: defaultLayout,
  className: 'modal',
  isOpen: false,
  shouldFocusAfterRender: true,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  shouldReturnFocusAfterClose: true
};
ModalNext.__docgenInfo = {
  componentName: "ModalNext",
  packageName: "@jutro/components",
  description: "ModalNext",
  displayName: "ModalNext",
  methods: [],
  actualName: "ModalNext",
  props: {
    contentLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: true
            },
            componentProps: {
              name: "object",
              required: false
            }
          }
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: false
            },
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: false
                }, {
                  value: "'auto-fill'",
                  computed: false
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: false
            },
            gap: {
              name: "enum",
              computed: true,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: false
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: false
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            }
          }
        }]
      },
      required: false,
      description: "defines the layout to be used with a 'component' property set to either Flex or Grid and\n'componentProperties' to set properties for that layout component",
      defaultValue: {
        value: "{\n    component: 'Grid',\n    componentProps: {\n        rows: ['auto', '1fr', 'auto'],\n        vgap: 'none',\n    },\n}",
        computed: false
      }
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating whether the Modal is currently open",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    onAfterOpen: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback function that, if provided, is called when the Modal dialog has been\nopened and is visible to the user"
    },
    onAfterClose: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback function that, if provided, is called when the Modal dialog has been\nclosed and is hidden from the user"
    },
    onRequestClose: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback function that, if provided, is called when the Modal dialog has been\nrequested to be closed (either by clicking on overlay or pressing ESC)"
    },
    closeTimeoutMS: {
      type: {
        name: "number"
      },
      required: false,
      description: "Number indicating the milliseconds to wait before closing the modal."
    },
    contentLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "String indicating how the content container should be announced to screenreaders"
    },
    overlayClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "The optional CSS class for the overlay for the Modal dialog"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "The optional CSS class for the the Modal dialog",
      defaultValue: {
        value: "'modal'",
        computed: false
      }
    },
    shouldFocusAfterRender: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating whether the Modal will autofocus to itself on open",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    shouldCloseOnOverlayClick: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional boolean flag that controls the interactability of the overlay. When false will not close the dialog when the\noverlay is clicked.",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    shouldCloseOnEsc: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating whether keyboard support for closing the Modal is\navailable (via the ESC key)",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    shouldReturnFocusAfterClose: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating if the Modal should restore focus to the element that\nhad focus prior to its display.",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function that will be called to get the parent element that the Modal will be attached to."
    },
    ariaLabelledby: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value for the modal's aria-labelledby attribute.\n\"Gives the dialog an accessible name by referring to the element that provides the dialog title.\""
    },
    ariaDescribedby: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value for the modal's aria-describedby attribute.\n\"Gives the dialog an accessible description by referring to the dialog content\n     that describes the primary message or purpose of the dialog.\""
    }
  }
};