import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useCallback } from 'react';
import { Accordion, AccordionCard } from '@jutro/components';
import { DropdownMenuSeparator } from '../DropdownMenu/DropdownMenuSeparator';
import { AppSwitcherElement } from './AppSwitcherElement';
import { isGroup, isSeparator } from './helpers';
import styles from "./AppSwitcher.module.css";
export var AppSwitcherContent = function AppSwitcherContent(_ref) {
  var items = _ref.items,
    displaySearch = _ref.displaySearch,
    renderSearch = _ref.renderSearch,
    resetSearch = _ref.resetSearch,
    onRouteClick = _ref.onRouteClick,
    closePopover = _ref.closePopover,
    isCollapsible = _ref.isCollapsible;
  var renderItem = useCallback(function (item) {
    if (isSeparator(item)) return React.createElement(DropdownMenuSeparator, {
      key: item.id,
      className: styles.separator
    });
    return React.createElement(AppSwitcherElement, {
      key: item.id,
      item: item,
      onRouteClick: onRouteClick,
      closePopover: closePopover,
      resetSearch: resetSearch
    });
  }, []);
  var renderItemArray = useCallback(function (itemArray) {
    return itemArray === null || itemArray === void 0 ? void 0 : _mapInstanceProperty(itemArray).call(itemArray, function (item) {
      return renderItem(item);
    });
  }, []);
  var renderGroupOfItems = useCallback(function (group, showSeparator) {
    var defaultOpenedId = !group.isInitiallyCollapsed || !isCollapsible ? "appSwitcherGroup_".concat(group.id) : undefined;
    return React.createElement(React.Fragment, {
      key: group.id
    }, showSeparator && React.createElement(DropdownMenuSeparator, {
      className: styles.separator
    }), React.createElement(Accordion, {
      id: "accordion_".concat(group.id),
      boldFont: true,
      showFrame: false,
      closeOthers: false,
      defaultOpenedId: defaultOpenedId
    }, React.createElement(AccordionCard, {
      id: "appSwitcherGroup_".concat(group.id),
      title: group.title,
      cardBodyClassName: styles.accordionCard,
      cardHeadingClassName: styles.groupHeading,
      chevron: true,
      chevronAlignment: "right",
      isCollapsible: isCollapsible
    }, renderItemArray(group.items))));
  }, []);
  return React.createElement("div", {
    className: styles.appSwitcherContent
  }, displaySearch && renderSearch && renderSearch(), React.createElement("div", {
    className: styles.appSwitcherItems
  }, _mapInstanceProperty(items).call(items, function (item, index) {
    return isGroup(item) ? renderGroupOfItems(item, !!index || displaySearch) : renderItem(item);
  })));
};
AppSwitcherContent.displayName = 'AppSwitcherContent';
AppSwitcherContent.__docgenInfo = {
  componentName: "AppSwitcherContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherContent",
  methods: [],
  actualName: "AppSwitcherContent"
};