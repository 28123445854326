import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  viewAll: {
    id: 'jutro-components.widgets.AppSwitcher.viewAll',
    defaultMessage: 'View All'
  },
  viewLess: {
    id: 'jutro-components.widgets.AppSwitcher.viewLess',
    defaultMessage: 'View Less'
  },
  appSwitcher: {
    id: 'jutro-components.widgets.AppSwitcher.appSwitcher',
    defaultMessage: 'Application Switcher'
  },
  searchPlaceholder: {
    id: 'jutro-components.widgets.AppSwitcher.searchPlaceholder',
    defaultMessage: 'Search Applications'
  }
});