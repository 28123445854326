import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { log } from '@jutro/logger';
import { useModalPromise } from './useModalPromise';
import { ModalNext } from './ModalNext';
import { AlertModal } from './AlertModal/AlertModal';
import { ConfirmationModal } from './ConfirmationModal/ConfirmationModal';
var createNoProviderWarning = function createNoProviderWarning(methodName) {
  return function () {
    return log.error("You should not use \"".concat(methodName, "()\" outside a <ModalNextProvider>"));
  };
};
function getModalRoot() {
  var modalRoot = document.getElementById('modalRoot');
  if (modalRoot) {
    return modalRoot;
  }
  var deprecatedModalRoot = document.getElementById('confirmation');
  if (deprecatedModalRoot) {
    log.warning('ModalNextProvider: Found deprecated <div id="confirmation">. Please use <div id="modalRoot"> instead.');
    return deprecatedModalRoot;
  }
  log.warning('ModalNextProvider: Unable to find <div id="modalRoot">. Please add at the bottom of the <body> in your index.html.');
  return document.body;
}
export var ModalNextProvider = function ModalNextProvider(_ref) {
  var appElementId = _ref.appElementId;
  var _useModalPromise = useModalPromise(),
    _useModalPromise2 = _slicedToArray(_useModalPromise, 4),
    isOpenAlert = _useModalPromise2[0],
    showAlertPromise = _useModalPromise2[1],
    resolveAlert = _useModalPromise2[2],
    rejectAlert = _useModalPromise2[3];
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    alertRender = _useState2[0].alertRender,
    setAlertState = _useState2[1];
  var _useModalPromise3 = useModalPromise(),
    _useModalPromise4 = _slicedToArray(_useModalPromise3, 4),
    isOpenModal = _useModalPromise4[0],
    showModalPromise = _useModalPromise4[1],
    resolveModal = _useModalPromise4[2],
    rejectModal = _useModalPromise4[3];
  var _useState3 = useState({}),
    _useState4 = _slicedToArray(_useState3, 2),
    modalRender = _useState4[0].modalRender,
    setModalState = _useState4[1];
  ModalNext.handleAfterClose = useCallback(function () {
    if (!isOpenAlert) {
      setAlertState({});
    }
    if (!isOpenModal) {
      setModalState({});
    }
  }, [isOpenAlert, isOpenModal, setAlertState, setModalState]);
  var context = useMemo(function () {
    var showAlert = function showAlert(_ref2) {
      var status = _ref2.status,
        icon = _ref2.icon,
        title = _ref2.title,
        message = _ref2.message,
        confirmButtonText = _ref2.confirmButtonText;
      setAlertState({
        alertRender: function alertRender(alertProps) {
          return React.createElement(AlertModal, _extends({}, alertProps, {
            parentSelector: getModalRoot,
            title: title,
            message: message,
            status: status,
            icon: icon,
            confirmButtonText: confirmButtonText
          }));
        }
      });
      return showAlertPromise();
    };
    var showConfirm = function showConfirm(_ref3) {
      var status = _ref3.status,
        icon = _ref3.icon,
        title = _ref3.title,
        message = _ref3.message,
        confirmButtonText = _ref3.confirmButtonText,
        cancelButtonText = _ref3.cancelButtonText;
      setAlertState({
        alertRender: function alertRender(alertProps) {
          return React.createElement(ConfirmationModal, _extends({}, alertProps, {
            parentSelector: getModalRoot,
            title: title,
            message: message,
            status: status,
            icon: icon,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
          }));
        }
      });
      return showAlertPromise();
    };
    var showModal = function showModal(component) {
      setModalState({
        modalRender: function modalRender(modalProps) {
          return React.cloneElement(component, modalProps);
        }
      });
      return showModalPromise();
    };
    return {
      showAlert: showAlert,
      showConfirm: showConfirm,
      showModal: showModal
    };
  }, [showAlertPromise, setAlertState, showModalPromise, setModalState]);
  useEffect(function () {
    var elementExists = document.getElementById(appElementId);
    if (elementExists) {
      ReactModal.setAppElement("#".concat(appElementId));
    }
  }, []);
  ModalNextProvider.modalNextContext = context;
  return React.createElement(React.Fragment, null, alertRender && alertRender({
    isOpen: isOpenAlert,
    onResolve: resolveAlert,
    onReject: rejectAlert
  }), modalRender && modalRender({
    isOpen: isOpenModal,
    onResolve: resolveModal,
    onReject: rejectModal
  }));
};
ModalNextProvider.propTypes = {
  appElementId: PropTypes.string,
  children: PropTypes.node
};
ModalNextProvider.defaultProps = {
  appElementId: 'root'
};
ModalNextProvider.modalNextContext = {
  showAlert: createNoProviderWarning('showAlert'),
  showConfirm: createNoProviderWarning('showConfirm'),
  showModal: createNoProviderWarning('showModal')
};
ModalNextProvider.showAlert = function (props) {
  var _ModalNextProvider$mo;
  return (_ModalNextProvider$mo = ModalNextProvider.modalNextContext) === null || _ModalNextProvider$mo === void 0 ? void 0 : _ModalNextProvider$mo.showAlert(props);
};
ModalNextProvider.showConfirm = function (props) {
  var _ModalNextProvider$mo2;
  return (_ModalNextProvider$mo2 = ModalNextProvider.modalNextContext) === null || _ModalNextProvider$mo2 === void 0 ? void 0 : _ModalNextProvider$mo2.showConfirm(props);
};
ModalNextProvider.showModal = function (component) {
  var _ModalNextProvider$mo3;
  return (_ModalNextProvider$mo3 = ModalNextProvider.modalNextContext) === null || _ModalNextProvider$mo3 === void 0 ? void 0 : _ModalNextProvider$mo3.showModal(component);
};
ModalNextProvider.__docgenInfo = {
  componentName: "ModalNextProvider",
  packageName: "@jutro/components",
  description: "",
  displayName: "ModalNextProvider",
  methods: [],
  actualName: "ModalNextProvider",
  props: {
    appElementId: {
      type: {
        name: "string"
      },
      required: false,
      description: "ID of the root element of your app, used to hide the app from accessibility tools on modal popup.\nThe root element must not be a parent of the modal root (from getModalRoot()), or else the content of the modal will be hidden as well.\nDefaults to `\"root\"`",
      defaultValue: {
        value: "'root'",
        computed: false
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: ""
    }
  }
};