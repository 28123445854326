import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext, useCallback } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import isUndefined from "lodash/isUndefined";
import isString from "lodash/isString";
import { IconButton, Link, NotificationAction, Header, HeaderActions, LogoTitle, HelpPopover } from '@jutro/components';
import { AuthContext } from '@jutro/auth';
import { useBreakpoint } from '@jutro/layout';
import { TranslatorContext, isIntlShape } from '@jutro/locale';
import { applicationHeaderPropTypes } from '@jutro/prop-types';
import { uniqueInnerId } from '@jutro/platform';
import { AppSwitcher } from '../AppSwitcher/AppSwitcher';
import { DropdownMenuAvatar } from '../DropdownMenu/DropdownMenuAvatar';
import { DropdownMenuAvatarContent } from '../DropdownMenu/DropdownMenuAvatarContent';
import { DropdownMenuSeparator } from '../DropdownMenu/DropdownMenuSeparator';
import { DropdownMenuLink } from '../DropdownMenu/DropdownMenuLink';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { BurgerMenuItem } from '../BurgerMenu/BurgerMenuItem';
import { BurgerMenuLink } from '../BurgerMenu/BurgerMenuLink';
import burgerMenuStyles from "../BurgerMenu/BurgerMenu.module.css";
import styles from "./ApplicationHeader.module.css";
import { messages } from './ApplicationHeader.messages';
import { SearchField } from './SearchField';
import { BurgerAppSwitcherButton } from '../BurgerMenu/BurgerAppSwitcherButton';
export var ApplicationHeader = function ApplicationHeader(props) {
  var translator = useContext(TranslatorContext);
  var auth = useContext(AuthContext);
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps,
    breakpoint = _useBreakpoint.breakpoint;
  var className = breakpointProps.className,
    logoSrc = breakpointProps.logoSrc,
    logoTitle = breakpointProps.logoTitle,
    logoUrl = breakpointProps.logoUrl,
    onSearchValueChange = breakpointProps.onSearchValueChange,
    onLoadValues = breakpointProps.onLoadValues,
    showNotifications = breakpointProps.showNotifications,
    notificationChildren = breakpointProps.notificationChildren,
    showAvatarProp = breakpointProps.showAvatar,
    avatarChildren = breakpointProps.avatarChildren,
    avatarProps = breakpointProps.avatarProps,
    searchFieldPlaceholder = breakpointProps.searchFieldPlaceholder,
    showAppSwitcher = breakpointProps.showAppSwitcher,
    appSwitcherItems = breakpointProps.appSwitcherItems,
    useAuthInfo = breakpointProps.useAuthInfo,
    commonAvatarRoutes = breakpointProps.commonAvatarRoutes,
    showHelp = breakpointProps.showHelp,
    helpUrl = breakpointProps.helpUrl,
    helpPopoverItems = breakpointProps.helpPopoverItems,
    searchAvailableValues = breakpointProps.searchAvailableValues,
    callbackMap = breakpointProps.callbackMap,
    appSwitcherFooterText = breakpointProps.appSwitcherFooterText,
    appSwitcherFooterUrl = breakpointProps.appSwitcherFooterUrl,
    onAppSwitcherFooterClick = breakpointProps.onAppSwitcherFooterClick,
    appSwitcherSearchEnabled = breakpointProps.appSwitcherSearchEnabled,
    appSwitcherCollapsibleGroupsThreshold = breakpointProps.appSwitcherCollapsibleGroupsThreshold,
    appSwitcherHideFooter = breakpointProps.appSwitcherHideFooter;
  var classes = cx(styles.applicationHeader, className);
  var wrapperClasses = cx(styles.applicationHeaderWrapper, className);
  var shouldShowHelp = showHelp === undefined && helpUrl ? true : showHelp;
  var showAvatar = showAvatarProp && (auth && auth.authenticated || !useAuthInfo);
  var HelpUrl = function HelpUrl(_ref) {
    var children = _ref.children;
    var url = typeof helpUrl === 'string' || isIntlShape(helpUrl) ? helpUrl : helpUrl === null || helpUrl === void 0 ? void 0 : helpUrl.href;
    return React.createElement(Link, {
      href: url,
      textClassName: styles.helpIcon,
      target: "_blank"
    }, children);
  };
  var headerLogo = (logoSrc || logoTitle) && React.createElement("div", {
    className: styles.logoWrapper
  }, React.createElement(NavLink, {
    to: logoUrl,
    className: styles.applicationLogoTitleLink
  }, React.createElement(LogoTitle, {
    src: logoSrc,
    logoClassName: styles.applicationLogo,
    titleClassName: styles.logoTitleText,
    alt: translator(messages.logoAlt),
    title: logoTitle
  })));
  var headerNotifications = showNotifications && React.createElement("span", null, React.createElement(NotificationAction, {
    id: "notificationAction",
    title: messages.notifications,
    isFlipEnabled: false,
    renderTrigger: function renderTrigger(toggle, ref, hasShownPopover) {
      return React.createElement(IconButton, {
        id: "notificationButton",
        icon: "mi-notifications",
        iconColor: "light",
        ref: ref,
        onClick: toggle,
        className: styles.headerActionButton,
        "aria-label": translator(messages.notificationButton),
        "aria-haspopup": "menu",
        "aria-expanded": hasShownPopover
      });
    }
  }, notificationChildren));
  var iconButtonProps = {
    id: 'helpButton',
    'data-testid': 'help-icon',
    icon: 'mi-help',
    iconColor: 'light',
    className: styles.headerActionButton,
    'aria-label': translator(messages.help)
  };
  var renderTrigger = useCallback(function (toggle, ref, hasShownPopover) {
    return React.createElement(IconButton, _extends({}, iconButtonProps, {
      ref: ref,
      onClick: toggle,
      "aria-haspopup": "menu",
      "aria-expanded": hasShownPopover
    }));
  }, [iconButtonProps]);
  var _uniqueInnerId = uniqueInnerId('ApplicationHeader', 'helpPopoverId'),
    helpPopoverId = _uniqueInnerId.helpPopoverId;
  var helpPopover = shouldShowHelp && React.createElement("span", null, React.createElement(HelpPopover, {
    id: helpPopoverId,
    renderTrigger: renderTrigger,
    footerLink: helpUrl
  }, helpPopoverItems));
  var helpLink = shouldShowHelp && React.createElement(HelpUrl, null, React.createElement(IconButton, iconButtonProps));
  var helpItem = (helpPopoverItems === null || helpPopoverItems === void 0 ? void 0 : helpPopoverItems.length) > 0 ? helpPopover : helpLink;
  var wrapWithCenterWrapper = function wrapWithCenterWrapper(element) {
    return onSearchValueChange && React.createElement("div", {
      className: styles.centerWrapper
    }, element);
  };
  var renderHeaderSearchField = function renderHeaderSearchField(searchColorTheme) {
    return React.createElement(SearchField, {
      isDarkTheme: searchColorTheme === 'dark',
      onSearchValueChange: onSearchValueChange,
      onLoadValues: onLoadValues,
      searchAvailableValues: searchAvailableValues,
      searchFieldPlaceholder: searchFieldPlaceholder
    });
  };
  var preventDefaultCallback = function preventDefaultCallback(callback) {
    return function (evt) {
      evt.preventDefault();
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      callback.apply(void 0, [evt].concat(args));
    };
  };
  var handleCallbackRedirect = function handleCallbackRedirect(callback, shouldRedirect) {
    return shouldRedirect ? callback : preventDefaultCallback(callback);
  };
  var commonAvatarContent = commonAvatarRoutes === null || commonAvatarRoutes === void 0 ? void 0 : _mapInstanceProperty(commonAvatarRoutes).call(commonAvatarRoutes, function (_ref2, index) {
    var title = _ref2.title,
      onClick = _ref2.onClick,
      linkClassName = _ref2.className,
      rest = _objectWithoutProperties(_ref2, ["title", "onClick", "className"]);
    var to = rest.to,
      href = rest.href;
    var shouldRedirect = !isUndefined(to || href);
    var callback = isString(onClick) ? callbackMap === null || callbackMap === void 0 ? void 0 : callbackMap[onClick] : onClick;
    var resolvedCallback = callback ? {
      onClick: handleCallbackRedirect(callback, shouldRedirect)
    } : {};
    var dropdownProps = _objectSpread({}, rest, {}, resolvedCallback);
    return React.createElement(DropdownMenuLink, _extends({
      key: "".concat(index),
      className: cx(linkClassName, burgerMenuStyles.sidebarItem, burgerMenuStyles.sidebarItemInteractive)
    }, dropdownProps), title);
  });
  var dropdownContentClassNames = {
    headerClassName: cx(burgerMenuStyles.sidebarItem, styles.burgerMenuAvatar),
    authLinksClassName: cx(burgerMenuStyles.sidebarItem, burgerMenuStyles.sidebarItemInteractive),
    separatorClassName: burgerMenuStyles.sidebarSeparator
  };
  var getHeaderAvatar = useCallback(function () {
    if (!showAvatar) {
      return null;
    }
    return React.createElement(DropdownMenuAvatar, _extends({
      id: "dropdownavatar",
      dropUp: false,
      alignRight: true,
      useAuthInfo: useAuthInfo,
      className: "withLightFocusOnApplicationHeader"
    }, dropdownContentClassNames, avatarProps), avatarChildren, commonAvatarContent);
  }, [avatarChildren, avatarProps, commonAvatarContent, useAuthInfo, showAvatar]);
  var _renderAppSwitcher = function renderAppSwitcher(isDropdown, onRouteClick) {
    return React.createElement(AppSwitcher, {
      id: "appSwitcher",
      items: appSwitcherItems,
      onRouteClick: onRouteClick || undefined,
      iconColor: "light",
      iconClassName: styles.headerActionButton,
      footerText: appSwitcherFooterText,
      footerUrl: appSwitcherFooterUrl,
      onFooterClick: onAppSwitcherFooterClick,
      isDropdown: isDropdown,
      searchEnabled: appSwitcherSearchEnabled,
      collapsibleGroupsThreshold: appSwitcherCollapsibleGroupsThreshold,
      hideFooter: appSwitcherHideFooter
    });
  };
  var mobileBurgerContent = [onSearchValueChange && React.createElement(BurgerMenuItem, {
    id: "burger-search-menu",
    key: "burger-search-menu",
    isContainerOnly: true,
    className: styles.burgerMenuSidebarStyledContent
  }, renderHeaderSearchField()), shouldShowHelp && React.createElement(BurgerMenuLink, {
    id: "burger-menu-help",
    key: "burger-menu-help",
    href: typeof helpUrl === 'string' || isIntlShape(helpUrl) ? helpUrl : helpUrl === null || helpUrl === void 0 ? void 0 : helpUrl.href,
    target: "_blank",
    rel: "noopener noreferrer",
    "data-testid": "help-link"
  }, translator(messages.help)), showAppSwitcher && React.createElement(BurgerAppSwitcherButton, {
    icon: "mi-apps",
    key: "burger-app-switcher",
    title: messages.switchApplication,
    renderAppSwitcher: function renderAppSwitcher(onRouteClick) {
      return _renderAppSwitcher(false, onRouteClick);
    }
  }), showAvatar && React.createElement(React.Fragment, {
    key: "burger-avatar"
  }, React.createElement(DropdownMenuSeparator, {
    className: burgerMenuStyles.sidebarSeparator
  }), React.createElement(DropdownMenuAvatarContent, _extends({
    useAuthInfo: useAuthInfo
  }, dropdownContentClassNames, avatarProps), (avatarChildren || (commonAvatarContent === null || commonAvatarContent === void 0 ? void 0 : commonAvatarContent.length) > 0) && React.createElement(React.Fragment, null, avatarChildren, commonAvatarContent)))];
  var appSwitcher = showAppSwitcher && React.createElement("span", null, _renderAppSwitcher(true));
  if (!breakpoint || breakpoint === 'desktop') {
    return React.createElement("section", {
      className: wrapperClasses
    }, React.createElement(Header, {
      className: classes
    }, headerLogo, wrapWithCenterWrapper(renderHeaderSearchField('dark')), React.createElement(HeaderActions, {
      className: styles.headerActions
    }, helpItem, appSwitcher, headerNotifications, getHeaderAvatar())));
  }
  return React.createElement("section", {
    className: wrapperClasses
  }, React.createElement(Header, {
    className: classes
  }, React.createElement(BurgerMenu, {
    className: styles.burgerMenuWrapper,
    buttonClassName: styles.headerActionButton,
    burgerContent: mobileBurgerContent,
    separatorClassName: styles.burgerMenuSeparator
  }), headerLogo, React.createElement(HeaderActions, {
    className: styles.headerActions
  }, headerNotifications)));
};
ApplicationHeader.displayName = 'ApplicationHeader';
ApplicationHeader.propTypes = applicationHeaderPropTypes;
ApplicationHeader.defaultProps = {
  showNotifications: true,
  showAvatar: true,
  showAppSwitcher: true,
  searchFieldPlaceholder: messages.searchFieldPlaceholder,
  useAuthInfo: true,
  logoUrl: '/',
  callbackMap: {}
};
ApplicationHeader.__docgenInfo = {
  componentName: "ApplicationHeader",
  packageName: "@jutro/router",
  description: "ApplicationHeader is the default header for all Guidewire applications.\nIt contains all the typical controls for Guidewire apps.\nIf you want to add other controls to the header, use the Header component instead",
  displayName: "ApplicationHeader",
  methods: [],
  actualName: "ApplicationHeader",
  metadataType: "container",
  props: {
    showNotifications: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    },
    showAvatar: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    },
    showAppSwitcher: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    },
    searchFieldPlaceholder: {
      defaultValue: {
        value: "messages.searchFieldPlaceholder",
        computed: true
      },
      required: false
    },
    useAuthInfo: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    },
    logoUrl: {
      defaultValue: {
        value: "'/'",
        computed: false
      },
      required: false
    },
    callbackMap: {
      defaultValue: {
        value: "{}",
        computed: false
      },
      required: false
    }
  },
  composes: ["@jutro/prop-types"]
};