import React, { useCallback, useContext } from 'react';
import { useKeyPress, useAccessibleRef, combineRefs } from '@jutro/platform';
import { IconButton } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { messages } from './AppSwitcher.messages';
export var availableIconColors = ['light', 'neutral', 'dark'];
export var AppSwitcherButton = React.forwardRef(function (_ref, ref) {
  var id = _ref.id,
    togglePopover = _ref.togglePopover,
    hasShownPopover = _ref.hasShownPopover,
    iconColor = _ref.iconColor,
    iconClassName = _ref.iconClassName,
    isFocused = _ref.isFocused,
    onIndexFocusChange = _ref.onIndexFocusChange;
  var iconRef = useAccessibleRef(isFocused);
  var translator = useContext(TranslatorContext);
  var showPopover = useCallback(function () {
    if (!hasShownPopover && togglePopover) {
      togglePopover();
    }
  }, [hasShownPopover, togglePopover]);
  var handleArrowUpPress = useCallback(function (event, isDownKeyPress) {
    if (isDownKeyPress) {
      if (onIndexFocusChange) {
        onIndexFocusChange(-1);
      }
      showPopover();
    }
    event.preventDefault();
  }, [onIndexFocusChange, showPopover]);
  var handleArrowDownPress = useCallback(function (event, isDownKeyPress) {
    if (isDownKeyPress) {
      if (onIndexFocusChange) {
        onIndexFocusChange(0);
      }
      showPopover();
    }
    event.preventDefault();
  }, [onIndexFocusChange, showPopover]);
  useKeyPress(iconRef, ['ArrowUp'], handleArrowUpPress);
  useKeyPress(iconRef, ['ArrowDown'], handleArrowDownPress);
  return React.createElement(IconButton, {
    id: id,
    icon: "mi-apps",
    ref: combineRefs(ref, iconRef),
    onClick: togglePopover,
    iconColor: iconColor,
    className: iconClassName,
    "aria-haspopup": "menu",
    "aria-expanded": hasShownPopover,
    "aria-label": translator(messages.appSwitcher)
  });
});
AppSwitcherButton.displayName = 'AppSwitcherButton';
AppSwitcherButton.__docgenInfo = {
  componentName: "AppSwitcherButton",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcherButton",
  methods: [],
  actualName: "AppSwitcherButton"
};