import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useContext, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import { TranslatorContext } from '@jutro/locale';
import { IconButton } from '@jutro/components';
import styles from "./BurgerMenu.module.css";
import { messages } from './BurgerMenu.messages';
import { BurgerMenuItem } from './BurgerMenuItem';
var BurgerSidebarInternal = function BurgerSidebarInternal(_ref) {
  var isOpen = _ref.isOpen,
    setIsOpen = _ref.setIsOpen,
    burgerContent = _ref.burgerContent;
  var translator = useContext(TranslatorContext);
  var visibilityStyles = _defineProperty({}, styles.isClosed, !isOpen);
  var sidebarClassNames = cx(styles.burgerSidebar, visibilityStyles);
  var backdropClassNames = cx(styles.backdrop, visibilityStyles);
  var closeOnClick = function closeOnClick() {
    setIsOpen(false);
  };
  var location = useLocation();
  useEffect(closeOnClick, [location.pathname]);
  var burgerSidebarHeader = React.createElement(BurgerMenuItem, {
    id: "burger-menu-side-bar-header",
    className: styles.sidebarHeader,
    hideSeparator: true,
    isContainerOnly: true
  }, React.createElement("span", {
    className: styles.menu
  }, translator(messages.menuHeaderTitle)), React.createElement(IconButton, {
    icon: "mi-close",
    iconColor: "dark",
    onClick: closeOnClick,
    "aria-label": translator(messages.closeNavigationLabel)
  }));
  var focusLockWhiteList = function focusLockWhiteList(node) {
    var _document$getElementB;
    return (_document$getElementB = document.getElementById('appSwitcherModal')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.contains(node);
  };
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: sidebarClassNames,
    "aria-hidden": !isOpen
  }, React.createElement(FocusLock, {
    noFocusGuards: true,
    whiteList: focusLockWhiteList
  }, burgerSidebarHeader, burgerContent)), React.createElement("div", {
    className: backdropClassNames,
    onClick: closeOnClick,
    role: "presentation"
  }));
};
BurgerSidebarInternal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  burgerContent: PropTypes.arrayOf(PropTypes.node)
};
BurgerSidebarInternal.defaultProps = {
  isOpen: false
};
BurgerSidebarInternal.displayName = 'BurgerSidebar';
export var BurgerSidebar = withRouter(BurgerSidebarInternal);
BurgerSidebarInternal.__docgenInfo = {
  componentName: "BurgerSidebar",
  packageName: "@jutro/router",
  description: "BurgerSidebar renders content of burger menu in a sidebar",
  displayName: "BurgerSidebar",
  methods: [],
  actualName: "BurgerSidebarInternal",
  metadataType: "container",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Sidebar state",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    setIsOpen: {
      type: {
        name: "func"
      },
      required: false,
      description: "Sidebar set state function"
    },
    burgerContent: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: false,
      description: "Sidebar content"
    }
  }
};