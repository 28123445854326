import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
import { intlToShape } from './toShape';
var separatorObject = {
  separator: PropTypes.bool
};
export var separatorPropType = PropTypes.shape(separatorObject);
var appSwitcherRouteObject = {
  title: intlMessageShape.isRequired,
  to: intlToShape,
  href: intlMessageShape,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  isFocused: PropTypes.bool
};
export var appSwitcherRoutePropTypes = PropTypes.shape(appSwitcherRouteObject);
var appSwitcherRouteGroupObject = {
  title: intlMessageShape.isRequired,
  items: PropTypes.arrayOf(appSwitcherRoutePropTypes.isRequired).isRequired,
  isInitiallyCollapsed: PropTypes.bool
};
export var appSwitcherRouteGroupPropTypes = PropTypes.shape(appSwitcherRouteGroupObject);
export var appSwitcherItemsObject = PropTypes.arrayOf(PropTypes.oneOfType([appSwitcherRoutePropTypes, appSwitcherRouteGroupPropTypes, separatorPropType]).isRequired);