import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useKeyActive } from '@jutro/platform';
import styles from "./ClickableCard.module.css";
import { getKeyPressHandler } from '../../accessibility/getKeyPressHandler';
var clickableCardPropTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
export var ClickableCard = function ClickableCard(_ref) {
  var id = _ref.id,
    className = _ref.className,
    children = _ref.children,
    onClick = _ref.onClick,
    disabled = _ref.disabled;
  var ref = useRef();
  var isActiveKeyPressed = useKeyActive(ref);
  var handleClick = useCallback(function (evt) {
    if (evt.target === evt.currentTarget && onClick) {
      onClick();
    }
  }, [onClick]);
  var handleKeyPress = useCallback(getKeyPressHandler(handleClick), [handleClick]);
  var clickableCardClass = cx(_defineProperty({}, styles.activeKeypress, isActiveKeyPressed), styles.clickableCard, className);
  return React.createElement("button", {
    id: id,
    "aria-disabled": disabled,
    className: clickableCardClass,
    onClick: handleClick,
    onKeyPress: handleKeyPress,
    ref: ref
  }, children);
};
ClickableCard.propTypes = clickableCardPropTypes;
ClickableCard.defaultProps = {
  disabled: false
};
ClickableCard.__docgenInfo = {
  componentName: "ClickableCard",
  packageName: "@jutro/components",
  description: "ClickableCard",
  displayName: "ClickableCard",
  methods: [],
  actualName: "ClickableCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "A unique ID of this component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Card's content"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Card's click handler"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, Card component is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};