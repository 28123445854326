import _defineProperty2 from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { ComponentPropTypes } from '@jutro/prop-types';
import { AccordionCard } from './AccordionCard';
import styles from "./Accordion.module.css";
import { AccordionContext } from './AccordionContext';
export var Accordion = function (_Component) {
  _inherits(Accordion, _Component);
  var _super = _createSuper(Accordion);
  function Accordion(props) {
    var _props$accordionState;
    var _this;
    _classCallCheck(this, Accordion);
    _this = _super.call(this, props);
    _defineProperty(_assertThisInitialized(_this), "isAccordionOpen", function (accordionId) {
      var _context;
      return _indexOfInstanceProperty(_context = _this.state.openedAccordions).call(_context, accordionId) >= 0;
    });
    _defineProperty(_assertThisInitialized(_this), "toggleAccordionOpen", function (accordionId) {
      if (_this.props.disabled) {
        return;
      }
      _this.setState(function (prevState) {
        var _context2;
        var newOpenedAccordions = _sliceInstanceProperty(_context2 = prevState.openedAccordions).call(_context2);
        var index = _indexOfInstanceProperty(newOpenedAccordions).call(newOpenedAccordions, accordionId);
        if (index >= 0) {
          _spliceInstanceProperty(newOpenedAccordions).call(newOpenedAccordions, index, 1);
        } else if (_this.props.closeOthers) {
          newOpenedAccordions[0] = accordionId;
        } else {
          newOpenedAccordions.push(accordionId);
        }
        prevState.stateChangeCallBack(newOpenedAccordions);
        return {
          openedAccordions: newOpenedAccordions
        };
      });
    });
    var useStates = (_props$accordionState = props.accordionStates) !== null && _props$accordionState !== void 0 && _props$accordionState.length ? props.accordionStates : undefined;
    var openedAccordions = useStates || props.defaultOpenedId ? useStates || [props.defaultOpenedId] : [];
    var stateChangeCallBack = props.onUpdateAccordionStates;
    _this.state = {
      openedAccordions: openedAccordions,
      stateChangeCallBack: stateChangeCallBack
    };
    return _this;
  }
  _createClass(Accordion, [{
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var _this$props = this.props,
        closeOthers = _this$props.closeOthers,
        accordionStates = _this$props.accordionStates;
      if (!closeOthers && nextProps.closeOthers) {
        var openedAccordions = this.state.openedAccordions;
        var lastOpenedAccordion = openedAccordions.length - 1;
        var newOpenedAccordions = lastOpenedAccordion >= 0 ? [openedAccordions[lastOpenedAccordion]] : [];
        this.setState({
          openedAccordions: newOpenedAccordions
        });
      }
      if (!nextProps.closeOthers && nextProps.accordionStates && nextProps.accordionStates !== accordionStates) {
        this.setState({
          openedAccordions: nextProps.accordionStates
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
        className = _this$props2.className,
        children = _this$props2.children,
        onUpdateAccordionStates = _this$props2.onUpdateAccordionStates,
        showFrame = _this$props2.showFrame,
        boldFont = _this$props2.boldFont,
        disabled = _this$props2.disabled;
      var context = {
        isAccordionOpen: this.isAccordionOpen,
        toggleAccordionOpen: this.toggleAccordionOpen,
        stateCallBack: onUpdateAccordionStates,
        showFrame: showFrame
      };
      return React.createElement("div", {
        className: cx(styles.accordion, _defineProperty2(_defineProperty2(_defineProperty2({}, styles.normalFont, !boldFont), styles.accordionFrame, showFrame), styles.disabled, disabled), className),
        role: "menu",
        "aria-disabled": disabled,
        "aria-haspopup": "true",
        "aria-expanded": this.isAccordionOpen
      }, React.createElement(AccordionContext.Provider, {
        value: context
      }, children));
    }
  }]);
  return Accordion;
}(Component);
_defineProperty(Accordion, "propTypes", {
  id: PropTypes.string,
  accordionStates: PropTypes.array,
  boldFont: PropTypes.bool,
  className: PropTypes.string,
  children: ComponentPropTypes.childOfComponentType(AccordionCard),
  closeOthers: PropTypes.bool,
  defaultOpenedId: PropTypes.string,
  disabled: PropTypes.bool,
  showFrame: PropTypes.bool,
  onUpdateAccordionStates: PropTypes.func
});
_defineProperty(Accordion, "defaultProps", {
  closeOthers: true,
  onUpdateAccordionStates: _.noop,
  showFrame: true,
  boldFont: true,
  disabled: false
});
Accordion.__docgenInfo = {
  componentName: "Accordion",
  packageName: "@jutro/components",
  description: "The `Accordion` component is used to surround a set of `Card` components so that the user can open or close them.",
  displayName: "Accordion",
  methods: [{
    name: "isAccordionOpen",
    docblock: "Returns whether or not the `Accordion` with the given id is expanded by looking to see if that id is in the\nlist of `openedAccordions`.\n\n@param {string} accordionId - The id of the `Accordion` whose expanded state is desired\n@returns {boolean} - True if the `Accordion` is expanded, false otherwise",
    modifiers: [],
    params: [{
      name: "accordionId",
      description: "The id of the `Accordion` whose expanded state is desired",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: "True if the `Accordion` is expanded, false otherwise",
      type: {
        name: "boolean"
      }
    },
    description: "Returns whether or not the `Accordion` with the given id is expanded by looking to see if that id is in the\nlist of `openedAccordions`."
  }, {
    name: "toggleAccordionOpen",
    docblock: "Toggles the expand state of the `Accordion` with the given id.  First, look up to see if the `openedAccordions`\nlist contains the `accordionId`.  If it does, then remove it otherwise add it to the list, either at the end\nwhen `closeOthers` is `false` or at the first position when `closeOthers` is `true`.\n\n@param {string} accordionId - The id of the `Accordion` being toggled",
    modifiers: [],
    params: [{
      name: "accordionId",
      description: "The id of the `Accordion` being toggled",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: null,
    description: "Toggles the expand state of the `Accordion` with the given id.  First, look up to see if the `openedAccordions`\nlist contains the `accordionId`.  If it does, then remove it otherwise add it to the list, either at the end\nwhen `closeOthers` is `false` or at the first position when `closeOthers` is `true`."
  }],
  actualName: "Accordion",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify accordion"
    },
    accordionStates: {
      type: {
        name: "array"
      },
      required: false,
      description: "An array containing the expanded accordion ids"
    },
    boldFont: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Changes the font weight of the accordion header, set to `true` by default (bold)",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(AccordionCard)"
      },
      required: false,
      description: "The children elements to render inside this component"
    },
    closeOthers: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating whether other accordions should be collapsed when this one is expanded",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    defaultOpenedId: {
      type: {
        name: "string"
      },
      required: false,
      description: "The optional id of the accordion to be opened by default"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Property to make accordion disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    showFrame: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Shows the accordion borders when true, set to `true` by default",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    onUpdateAccordionStates: {
      type: {
        name: "func"
      },
      required: false,
      description: "A callback function for querying the list of expanded accordions",
      defaultValue: {
        value: "_.noop",
        computed: true
      }
    }
  }
};