import _defineProperty2 from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { isEmptyValue } from '@jutro/data';
import { DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_NUMBER } from '@jutro/prop-types';
import { logDeprecationMessage } from '@jutro/platform';
export var valueParserMap = _defineProperty2(_defineProperty2(_defineProperty2({}, DATA_TYPE_OBJECT, {
  getValue: function getValue(value) {
    return value && !isEmptyValue(value.amount) ? value.amount : '';
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return (value === null || value === void 0 ? void 0 : value.currency) || defaultCurrency;
  },
  newValue: function newValue(amount, currency) {
    return {
      amount: amount,
      currency: currency
    };
  }
}), DATA_TYPE_STRING, {
  getValue: function getValue(value) {
    return getAmountFromString(value);
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return getCurrencyFromString(value) || defaultCurrency;
  },
  newValue: function newValue(amount, currency) {
    return "".concat(isEmptyValue(amount) ? '' : amount, " ").concat(currency);
  }
}), DATA_TYPE_NUMBER, {
  getValue: function getValue(value) {
    return !isEmptyValue(value) ? value : null;
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return defaultCurrency;
  },
  newValue: function newValue(amount) {
    return amount;
  }
});
var splitValue = function splitValue(value) {
  return (value === null || value === void 0 ? void 0 : value.split) && value.split(/\s+/) || [];
};
var getAmountFromString = function getAmountFromString(value) {
  return splitValue(value)[0];
};
var getCurrencyFromString = function getCurrencyFromString(value) {
  return splitValue(value)[1];
};
export var Money = function (_Component) {
  _inherits(Money, _Component);
  var _super = _createSuper(Money);
  function Money() {
    _classCallCheck(this, Money);
    return _super.apply(this, arguments);
  }
  _createClass(Money, [{
    key: "parseValue",
    value: function parseValue() {
      var _this$props = this.props,
        value = _this$props.value,
        amount = _this$props.amount,
        defaultCurrency = _this$props.currency;
      var dataType = typeof value;
      var amountFromValue = valueParserMap[dataType].getValue(value);
      var currency = valueParserMap[dataType].getCurrency(value, defaultCurrency);
      return {
        amount: amountFromValue || amount,
        currency: currency
      };
    }
  }, {
    key: "renderNumber",
    value: function renderNumber() {
      var _this$props2 = this.props,
        absent = _this$props2.absent,
        currencyDisplay = _this$props2.currencyDisplay,
        showFractions = _this$props2.showFractions;
      var _this$parseValue = this.parseValue(),
        amount = _this$parseValue.amount,
        currency = _this$parseValue.currency;
      return amount && amount !== '-' || amount === 0 ? React.createElement(FormattedNumber, {
        value: amount,
        currency: currency,
        currencyDisplay: currencyDisplay,
        style: "currency",
        minimumFractionDigits: showFractions ? undefined : 0,
        maximumFractionDigits: showFractions ? undefined : 0
      }) : absent;
    }
  }, {
    key: "render",
    value: function render() {
      logDeprecationMessage('Money', 'CurrencyValue');
      var _this$props3 = this.props,
        tag = _this$props3.tag,
        className = _this$props3.className,
        id = _this$props3.id;
      var Tag = tag;
      return React.createElement(Tag, {
        id: id,
        className: className
      }, this.renderNumber());
    }
  }]);
  return Money;
}(Component);
_defineProperty(Money, "propTypes", {
  id: PropTypes.string,
  amount: PropTypes.number,
  absent: PropTypes.string,
  currency: PropTypes.string,
  tag: PropTypes.string,
  currencyDisplay: PropTypes.string,
  className: PropTypes.string,
  showFractions: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
  })])
});
_defineProperty(Money, "defaultProps", {
  tag: 'span',
  currency: 'usd',
  dataType: DATA_TYPE_NUMBER,
  value: null
});
Money.__docgenInfo = {
  componentName: "Money",
  packageName: "@jutro/components",
  description: "The `Money` component is used to render money value localized.  This component is built with the\n`amount` prop as well as optional props: `currency`, `className`, `tag`, `minDigits`, and `maxDigits`.",
  displayName: "Money",
  methods: [{
    name: "parseValue",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderNumber",
    docblock: "Renders the formatted number or nothing if no amount\nis specified.\n@returns {React.ReactElement|undefined} - The formatted number",
    modifiers: [],
    params: [],
    returns: {
      description: "The formatted number",
      type: {
        name: "union",
        elements: [{
          name: "React.ReactElement"
        }, null]
      }
    },
    description: "Renders the formatted number or nothing if no amount\nis specified."
  }],
  actualName: "Money",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Unique Identifier"
    },
    amount: {
      type: {
        name: "number"
      },
      required: false,
      description: "The numeric value to display"
    },
    absent: {
      type: {
        name: "string"
      },
      required: false,
      description: "The string to display in the UI if 'amount' is undefined/null"
    },
    currency: {
      type: {
        name: "string"
      },
      required: false,
      description: "The currency name to display for localization",
      defaultValue: {
        value: "'usd'",
        computed: false
      }
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'span'",
        computed: false
      }
    },
    currencyDisplay: {
      type: {
        name: "string"
      },
      required: false,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays fractions with the amount"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "number",
              required: false
            },
            currency: {
              name: "string",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Value to display in the format of string|number|object",
      defaultValue: {
        value: "null",
        computed: false
      }
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_NUMBER",
        computed: true
      },
      required: false
    }
  }
};