import _defineProperty from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber as IntlFormattedNumber } from 'react-intl';
import { isEmptyValue } from '@jutro/data';
import { DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_NUMBER, currencyValueShape, intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { FieldComponent } from '../inputs/FieldComponent/FieldComponent';
export var valueParserMap = _defineProperty(_defineProperty(_defineProperty({}, DATA_TYPE_OBJECT, {
  getValue: function getValue(value) {
    return value && !isEmptyValue(value.amount) ? value.amount : '';
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return value && value.currency || defaultCurrency;
  },
  newValue: function newValue(amount, currency) {
    return {
      amount: amount,
      currency: currency
    };
  }
}), DATA_TYPE_STRING, {
  getValue: function getValue(value) {
    return getAmountFromString(value);
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return getCurrencyFromString(value) || defaultCurrency;
  },
  newValue: function newValue(amount, currency) {
    return "".concat(isEmptyValue(amount) ? '' : amount, " ").concat(currency);
  }
}), DATA_TYPE_NUMBER, {
  getValue: function getValue(value) {
    return !isEmptyValue(value) ? value : null;
  },
  getCurrency: function getCurrency(value, defaultCurrency) {
    return defaultCurrency;
  },
  newValue: function newValue(amount) {
    return amount;
  }
});
var splitValue = function splitValue(value) {
  return value && value.split && value.split(/\s+/) || [];
};
var getAmountFromString = function getAmountFromString(value) {
  return splitValue(value)[0];
};
var getCurrencyFromString = function getCurrencyFromString(value) {
  return splitValue(value)[1];
};
function parseValue(value, defaultAmount, defaultCurrency) {
  var amount = defaultAmount;
  var currency = defaultCurrency;
  if (value) {
    var dataType = typeof value;
    amount = valueParserMap[dataType].getValue(value);
    currency = valueParserMap[dataType].getCurrency(value, defaultCurrency);
  }
  return {
    amount: amount,
    currency: currency
  };
}
function getFormattedCurrencyProps(props, currencyObject) {
  var placeholder = props.placeholder,
    currencyDisplay = props.currencyDisplay,
    showFractions = props.showFractions,
    showGrouping = props.showGrouping,
    value = props.value,
    amountProp = props.amount,
    currencyProp = props.currency;
  var _parseValue = parseValue(currencyObject || value, (currencyObject === null || currencyObject === void 0 ? void 0 : currencyObject.amount) || amountProp, (currencyObject === null || currencyObject === void 0 ? void 0 : currencyObject.currency) || currencyProp),
    amount = _parseValue.amount,
    currency = _parseValue.currency;
  var hasValue = !isEmptyValue(amount);
  if (!hasValue) {
    return {
      placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : FieldComponent.defaultROEmptyValue
    };
  }
  return {
    value: amount,
    style: 'currency',
    currency: currency,
    currencyDisplay: currencyDisplay,
    useGrouping: showGrouping,
    minimumFractionDigits: showFractions ? undefined : 0,
    maximumFractionDigits: showFractions ? undefined : 0
  };
}
var formattedCurrencyPropTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  currencyDisplay: PropTypes.oneOf(['code', 'symbol', 'name']),
  showFractions: PropTypes.bool,
  showGrouping: PropTypes.bool,
  value: currencyValueShape,
  placeholder: intlMessageShape
};
export var FormattedCurrency = function FormattedCurrency(props) {
  var _getFormattedCurrency = getFormattedCurrencyProps(props),
    placeholder = _getFormattedCurrency.placeholder,
    formattedCurrencyProps = _objectWithoutProperties(_getFormattedCurrency, ["placeholder"]);
  var translator = useContext(TranslatorContext);
  if (placeholder) {
    return React.createElement(React.Fragment, null, translator(placeholder));
  }
  return React.createElement(IntlFormattedNumber, formattedCurrencyProps);
};
FormattedCurrency.propTypes = formattedCurrencyPropTypes;
FormattedCurrency.defaultProps = {
  currency: 'usd',
  currencyDisplay: 'symbol',
  placeholder: FieldComponent.defaultROEmptyValue,
  showGrouping: true
};
export function formatCurrency(intl, props, currencyObject) {
  var _getFormattedCurrency2 = getFormattedCurrencyProps(props, currencyObject),
    placeholder = _getFormattedCurrency2.placeholder,
    value = _getFormattedCurrency2.value,
    formattedCurrencyProps = _objectWithoutProperties(_getFormattedCurrency2, ["placeholder", "value"]);
  if (placeholder) {
    return placeholder;
  }
  return intl.formatNumber(value, formattedCurrencyProps);
}
FormattedCurrency.__docgenInfo = {
  componentName: "FormattedCurrency",
  packageName: "@jutro/components",
  description: "The `FormattedCurrency` component is used to render a formatted currency value.",
  displayName: "FormattedCurrency",
  methods: [],
  actualName: "FormattedCurrency",
  metadataType: "element",
  props: {
    amount: {
      type: {
        name: "number"
      },
      required: false,
      description: "The numeric value to display"
    },
    currency: {
      type: {
        name: "string"
      },
      required: false,
      description: "The currency name to display for localization",
      defaultValue: {
        value: "'usd'",
        computed: false
      }
    },
    currencyDisplay: {
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: false
        }, {
          value: "'symbol'",
          computed: false
        }, {
          value: "'name'",
          computed: false
        }]
      },
      required: false,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'",
      defaultValue: {
        value: "'symbol'",
        computed: false
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays fractions with the amount"
    },
    showGrouping: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays grouping separators with the value",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            currency: {
              name: "string",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The string to display in the UI if 'amount' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: true
      }
    }
  }
};