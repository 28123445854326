import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isIntlShape, TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
var intlElementPropTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  children: intlMessageShape
};
export var IntlElement = function IntlElement(_ref) {
  var Tag = _ref.tag,
    children = _ref.children,
    dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
    others = _objectWithoutProperties(_ref, ["tag", "children", "dangerouslySetInnerHTML"]);
  var translator = useContext(TranslatorContext);
  var content = isIntlShape(children) ? translator(children) : children;
  return React.createElement(Tag, others, content);
};
IntlElement.propTypes = intlElementPropTypes;
IntlElement.__docgenInfo = {
  componentName: "IntlElement",
  packageName: "@jutro/components",
  description: "IntlElement",
  displayName: "IntlElement",
  methods: [],
  actualName: "IntlElement",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "A unique ID of this element"
    },
    tag: {
      type: {
        name: "string"
      },
      required: true,
      description: "The html tag to use when rendering the translated message"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message that should be translated"
    }
  }
};