import _toConsumableArray from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _defineProperty2 from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { isValid, isBefore, isAfter } from 'date-fns';
import defaultLocaleObject from 'date-fns/locale/en-US';
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import cx from 'classnames';
import { isEmptyValue } from '@jutro/data';
import { mergeStyles, getMessageProp } from '@jutro/platform';
import { messages } from './DateField.messages';
import datePickerStyles from "./DatePickerField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { CustomHeader } from './CustomHeader';
import { PopperContainer } from './PopperContainer';
import { DateValue } from '../../values/DateValue';
import { getPatternFromLocale, getInputDateFormats, normalizeInput, formatDateToDataType, isDateShapeInRange, parseDateShapeToDate } from './helpers';
import { maxDefaultYear, minDefaultYear, getDateFormat } from './DateField.commons';
function registerDateFnsLocale(locale) {
  registerLocale(locale.code, locale);
}
export var DateFieldInternal = function (_FieldComponent) {
  _inherits(DateFieldInternal, _FieldComponent);
  var _super = _createSuper(DateFieldInternal);
  function DateFieldInternal(_props) {
    var _this;
    _classCallCheck(this, DateFieldInternal);
    _this = _super.call(this, _props);
    _defineProperty(_assertThisInitialized(_this), "datePickerRef", void 0);
    _defineProperty(_assertThisInitialized(_this), "localePattern", void 0);
    _defineProperty(_assertThisInitialized(_this), "userInput", void 0);
    _defineProperty(_assertThisInitialized(_this), "state", {
      isValidDate: true,
      isOpen: _this.props.isInitiallyOpen
    });
    _defineProperty(_assertThisInitialized(_this), "formatValue", function (value) {
      var dataType = _this.props.dataType;
      return formatDateToDataType(value, dataType);
    });
    _defineProperty(_assertThisInitialized(_this), "handleDateChange", function (date) {
      if (_this.userInput) {
        date = new Date(new Date(_this.userInput).toUTCString());
        _this.userInput = undefined;
      }
      var isEmptyDate = isEmptyValue(date);
      var isValidDate = isEmptyDate || isValid(date) && _this.isInRange(date);
      var value = !isEmptyDate && isValid(date) ? date : undefined;
      _this.setState({
        isValidDate: isValidDate
      });
      _this.notifyChange(value ? _this.formatValue(value) : value);
    });
    _defineProperty(_assertThisInitialized(_this), "handleChangeRaw", function (evt) {
      if (!evt.target.value) {
        return;
      }
      var userInput = normalizeInput(evt.target.value, _this.localePattern);
      var date = new Date(userInput);
      _this.userInput = userInput;
      if (_Number$isNaN(date.getTime()) || !_this.isInRange(date)) {
        _this.handleDateChange(date);
      }
    });
    _defineProperty(_assertThisInitialized(_this), "isInRange", function (date) {
      var _this$props = _this.props,
        minDate = _this$props.minDate,
        maxDate = _this$props.maxDate;
      return isDateShapeInRange(date, minDate, maxDate);
    });
    _defineProperty(_assertThisInitialized(_this), "renderPopperContainer", function (labelId) {
      return function (props) {
        return React.createElement(PopperContainer, _extends({}, props, {
          labelId: labelId,
          parentId: _this.props.id
        }));
      };
    });
    _defineProperty(_assertThisInitialized(_this), "renderCustomHeader", function (props) {
      var _this$props2 = _this.props,
        minDate = _this$props2.minDate,
        maxDate = _this$props2.maxDate,
        locale = _this$props2.locale;
      var min = minDate ? parseDateShapeToDate(minDate).getUTCFullYear() : minDefaultYear;
      var max = maxDate ? parseDateShapeToDate(maxDate).getUTCFullYear() : maxDefaultYear;
      return React.createElement(CustomHeader, _extends({}, props, {
        locale: locale,
        minYear: min,
        maxYear: max
      }));
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnBlur", function (evt) {
      _this.focusPristine = false;
      _this.handleBlur(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "setFocus", function () {
      var _this$datePickerRef, _this$datePickerRef$c;
      (_this$datePickerRef = _this.datePickerRef) === null || _this$datePickerRef === void 0 ? void 0 : (_this$datePickerRef$c = _this$datePickerRef.current) === null || _this$datePickerRef$c === void 0 ? void 0 : _this$datePickerRef$c.setFocus();
    });
    _defineProperty(_assertThisInitialized(_this), "renderTodayButton", function (todayButtonText) {
      var _assertThisInitialize = _assertThisInitialized(_this),
        translator = _assertThisInitialize.translator;
      return React.createElement("button", {
        className: datePickerStyles.todayButton,
        "aria-label": translator(todayButtonText || messages.today)
      }, translator(todayButtonText || messages.today));
    });
    _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (e) {
      if (_this.state.isOpen && e.key === 'Tab' && e.target.id === _this.props.id) {
        e.preventDefault();
        var selectedDay = _this.datePickerRef.current.calendar.componentNode && _this.datePickerRef.current.calendar.componentNode.querySelector('.react-datepicker__day[tabindex="0"]');
        if (selectedDay) {
          selectedDay.focus({
            preventScroll: true
          });
        }
      }
    });
    var _locale = _props.locale;
    _this.datePickerRef = React.createRef();
    _this.localePattern = getPatternFromLocale(!isEmpty(_locale) ? _locale : defaultLocaleObject);
    registerDateFnsLocale(_locale);
    return _this;
  }
  _createClass(DateFieldInternal, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(DateFieldInternal.prototype), "render", this).call(this);
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props3 = this.props,
        defaultValue = _this$props3.defaultValue,
        value = _this$props3.value;
      if (!value && defaultValue) {
        var newValue = parseDateShapeToDate(defaultValue);
        this.handleDateChange(newValue);
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var prevValue = prevProps.value,
        prevMessages = prevProps.validationMessages,
        prevLocale = prevProps.locale;
      var _this$props4 = this.props,
        value = _this$props4.value,
        validationMessages = _this$props4.validationMessages,
        locale = _this$props4.locale;
      if (!isEqual(prevValue, value) || !isEqual(prevMessages, validationMessages)) {
        this.validate(value);
      }
      if (!isEqual(prevLocale, locale)) {
        registerDateFnsLocale(locale);
      }
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var isValidDate = this.state.isValidDate;
      var messageProps = this.props.messageProps;
      var incorrectInput = getMessageProp('incorrectInput', messageProps, messages);
      var validationMessages = _get(_getPrototypeOf(DateFieldInternal.prototype), "getValidationMessages", this).call(this) || [];
      return !isValidDate && isEmpty(validationMessages) ? [this.translator(incorrectInput)] : validationMessages;
    }
  }, {
    key: "getPlaceholderOrDefault",
    value: function getPlaceholderOrDefault(placeholder) {
      var translatedLocalPattern = this.localePattern.replace('dd', this.translator(messages.dd)).replace('MM', this.translator(messages.mm)).replace('yyyy', this.translator(messages.yy));
      if (placeholder) {
        return this.translator(placeholder, {
          format: translatedLocalPattern
        });
      }
      return translatedLocalPattern;
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        showTime = breakpointProps.showTime,
        format = breakpointProps.format;
      var classes = cx(datePickerStyles.datePicker, datePickerStyles.readOnly);
      return React.createElement(DateValue, {
        id: id,
        className: classes,
        value: value,
        format: format,
        showTime: showTime
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var _this2 = this;
      var combinedStyles = mergeStyles(inputStyles, datePickerStyles);
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        placeholder = breakpointProps.placeholder,
        minDate = breakpointProps.minDate,
        maxDate = breakpointProps.maxDate,
        autoComplete = breakpointProps.autoComplete,
        format = breakpointProps.format,
        todayButtonText = breakpointProps.todayButtonText,
        popperPlacement = breakpointProps.popperPlacement,
        value = breakpointProps.value,
        locale = breakpointProps.locale,
        showCalendar = breakpointProps.showCalendar,
        isInitiallyOpen = breakpointProps.isInitiallyOpen;
      var _this$state = this.state,
        calendarId = _this$state.calendarId,
        isOpen = _this$state.isOpen;
      var minDateObject = minDate ? parseDateShapeToDate(minDate) : null;
      var maxDateObject = maxDate ? parseDateShapeToDate(maxDate) : null;
      var nowWithoutTime = new Date();
      var isTodayBeforeMin = minDateObject ? isBefore(nowWithoutTime, minDateObject) : false;
      var isTodayAfterMax = maxDateObject ? isAfter(nowWithoutTime, maxDateObject) : false;
      var todayOutOfRange = isTodayBeforeMin || isTodayAfterMax;
      var inputClasses = cx({
        disabled: disabled,
        invalid: !options.isValid
      }, combinedStyles.input, combinedStyles.datePicker, controlClassName);
      var popperClasses = cx(_defineProperty2({}, combinedStyles.hidePopper, !showCalendar));
      var isRequired = required !== null && required !== void 0 ? required : undefined;
      var isDisabled = disabled !== null && disabled !== void 0 ? disabled : undefined;
      var calendarClass = cx({
        todayOutOfRange: todayOutOfRange
      });
      var selectedDate = value ? parseDateShapeToDate(value) : undefined;
      var inputElement = React.createElement(DatePicker, _extends({
        id: id,
        ref: this.datePickerRef,
        selected: typeof selectedDate === 'object' && isValid(selectedDate) ? selectedDate : null,
        onChange: this.handleDateChange,
        onChangeRaw: this.handleChangeRaw,
        onKeyDown: this.handleKeyDown,
        onSelect: this.setFocus,
        todayButton: this.renderTodayButton(todayButtonText),
        className: inputClasses,
        locale: locale.code,
        calendarClassName: calendarClass,
        minDate: minDateObject,
        maxDate: maxDateObject,
        popperPlacement: popperPlacement,
        popperModifiers: {
          preventOverflow: {
            enabled: true
          },
          flip: {
            behavior: ['bottom']
          }
        },
        customInput: React.createElement("input", _extends({
          inputMode: "none"
        }, this.generateAccessibilityProperties())),
        dateFormat: [getDateFormat(format)].concat(_toConsumableArray(getInputDateFormats(this.localePattern))),
        isClearable: false,
        popperClassName: popperClasses,
        popperContainer: this.renderPopperContainer(options.labelId),
        renderCustomHeader: this.renderCustomHeader,
        disabled: isDisabled,
        required: isRequired,
        autoComplete: autoComplete ? 'on' : 'off',
        placeholderText: this.getPlaceholderOrDefault(placeholder)
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), this.getInputFocusHandlers(), {
        onBlur: this.handleOnBlur,
        startOpen: isInitiallyOpen,
        open: isOpen,
        onCalendarOpen: function onCalendarOpen() {
          return _this2.setState({
            isOpen: true
          });
        },
        onCalendarClose: function onCalendarClose() {
          return _this2.setState({
            isOpen: false
          });
        }
      }));
      return React.createElement("div", {
        className: combinedStyles.datePicker,
        "aria-expanded": "true",
        "aria-haspopup": "true",
        "aria-controls": calendarId
      }, React.createElement(FieldIcon, breakpointProps, inputElement));
    }
  }]);
  return DateFieldInternal;
}(FieldComponent);
_defineProperty(DateFieldInternal, "displayName", 'DateFieldInternal');
DateFieldInternal.__docgenInfo = {
  componentName: "DateFieldInternal",
  packageName: "@jutro/components",
  description: "Allows the user to either type or select a date. Displays them according to locale settings.",
  displayName: "DateFieldInternal",
  methods: [{
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getPlaceholderOrDefault",
    docblock: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale.\n\n@param {intlMessageShape} [placeholder] placeholder provided by props\n@returns {string} placeholder",
    modifiers: [],
    params: [{
      name: "placeholder"
    }],
    returns: null,
    description: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "renderControl",
    docblock: "Render the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} [options] - some options?\n@returns {React.ReactElement} - The resulting rendered DateField",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options"
    }],
    returns: null,
    description: "Render the control"
  }],
  actualName: "DateFieldInternal",
  metadataType: "field"
};