import _objectWithoutProperties from "D:\\VE\\R2.1_Commit\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import { Button, Icon } from '@jutro/components';
import styles from "./ContextSwitcher.module.css";
export var ContextSwitcherButton = function ContextSwitcherButton(_ref) {
  var children = _ref.children,
    others = _objectWithoutProperties(_ref, ["children"]);
  return React.createElement(Button, _extends({
    className: styles.contextSwitcherButton,
    renderContent: function renderContent() {
      return React.createElement(React.Fragment, null, children, React.createElement(Icon, {
        icon: "mi-expand_more"
      }));
    }
  }, others));
};
ContextSwitcherButton.__docgenInfo = {
  componentName: "ContextSwitcherButton",
  packageName: "@jutro/router",
  description: "",
  displayName: "ContextSwitcherButton",
  methods: [],
  actualName: "ContextSwitcherButton"
};